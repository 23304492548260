import { Box, Typography } from "@mui/material";
import useScalableSizes from "../../shared/hooks/useScalableSizes";

interface RepairVsReplacementBoxProps {
  header: string;
  children: React.ReactNode;
  headerColor?: string;
}

export default function RepairVsReplacementBox({
  header,
  children,
  headerColor = "#454545",
}: RepairVsReplacementBoxProps) {
  // Context
  const { subHeaderTextSize, descriptionTextSize } = useScalableSizes();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        backgroundColor: "#E7E7E7",
        borderRadius: "16px",
        padding: "25px",
        boxShadow: "4px 6px 4px rgba(0, 0, 0, 0.15)",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Kanit",
          fontSize: subHeaderTextSize,
          color: headerColor,
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        {header}
      </Typography>
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontSize: descriptionTextSize,
          color: "#656565",
          paddingLeft: "10px",
          textAlign: "center",
        }}
      >
        {children}
      </Typography>
    </Box>
  );
}
