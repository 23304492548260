import { Box, Typography } from "@mui/material";
import { nobleRed } from "../../assets/Colors";
import CommonDescription from "../../shared/components/Common/CommonDescription";
import { smallGroupedSectionGap } from "../../shared/Constants/PaddingConstants";

interface BeSureToIncludeRowProps {
  text: string;
  number: number;
}

export default function BeSureToIncludeRow({
  text,
  number,
}: BeSureToIncludeRowProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        gap: smallGroupedSectionGap,
        width: "100%",
      }}
    >
      <Box
        sx={{
          backgroundColor: nobleRed,
          width: "45px",
          height: "45px",
          borderRadius: "80px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            color: "white",
            fontWeight: "bold",
            fontFamily: "Poppins",
          }}
        >
          {`${number}.`}
        </Typography>
      </Box>
      <CommonDescription>{text}</CommonDescription>
    </Box>
  );
}
