import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, IconButton, Zoom, darken } from "@mui/material";
import { nobleOrange, offBlack } from "../../assets/Colors";
import { useScrollPositionContext } from "../../shared/context/ScrollPositionContext";

export default function BackToTop() {
  // Constants
  const showButtonWhenPastScrollPosition = 100;
  const borderRadius = "200px";

  // Context
  const { resetScrollPosition, currentScrollPosition } =
    useScrollPositionContext();

  return (
    <Zoom in={currentScrollPosition > showButtonWhenPastScrollPosition}>
      <Box
        sx={{
          position: "fixed",
          zIndex: 10,
          alignItems: "center",
          display: "flex",
          justifyContent: "end",
          marginX: "10px",
          borderRadius: borderRadius,
          gap: "20px",
          right: 15,
          bottom: `15px`,
          transition: "bottom 0.3s",
        }}
      >
        <Box
          sx={{
            backgroundColor: nobleOrange,
            borderRadius: borderRadius,
            transition: "all 0.15s ease-in-out",
            "&:hover": {
              backgroundColor: darken(nobleOrange, 0.2),
            },
          }}
        >
          <IconButton onClick={resetScrollPosition}>
            <KeyboardArrowUpIcon
              sx={{
                color: offBlack,
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Zoom>
  );
}
