import { createTheme } from "@mui/material";
import { offBlack } from "../assets/Colors";

/**
 * The MUI theme for this web app.
 */
export const muiTheme = createTheme({
  palette: {
    primary: {
      main: offBlack,
    },
    background: {
      default: "#ffffff",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
          padding: 0,
          margin: 0,
          scrollBehavior: "smooth",
        },
        a: {
          color: "inherit",
          textDecoration: "none",
        },
        body: {
          background: "#ffffff",
        },
      },
    },
  },
});
