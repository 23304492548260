import { faCloud, faHouse, faUserTie } from "@fortawesome/free-solid-svg-icons";
import CommonDescription from "../../../shared/components/Common/CommonDescription";
import CommonRouteImageWithTextHeader from "../../../shared/components/Common/CommonRouteImageWithTextHeader";
import CommonSubHeader from "../../../shared/components/Common/CommonSubHeader";
import CommonRouteContainer from "../../../shared/components/container/CommonRouteContainer";
import SpringBox from "../../../shared/components/SpringBox";
import SummaryIconContainer from "../../../shared/components/Summary/SummaryIconContainer";

export default function ShingleRoofing() {
  return (
    <CommonRouteContainer
      titlePrefix="Shingle roofing"
      metaDescription="Shingle roofing systems are a popular and versatile choice for
        homeowners due to their durability, affordability, and aesthetic appeal"
    >
      <CommonRouteImageWithTextHeader
        image={require("./Shingles.png")}
        alt={"Shingle roofing"}
        header={"Shingle roofing systems"}
        subHeader={"Shingles built to last"}
        aria-label="Header with shingles photo"
      />
      <SummaryIconContainer
        aria-label="Summary icons"
        icons={[
          {
            icon: faHouse,
            text: "Custom shingle options",
          },
          {
            icon: faUserTie,
            text: "Professional installation",
          },
          {
            icon: faCloud,
            text: "Durable and weatherproof",
          },
        ]}
      />
      <CommonDescription aria-label="About shingle roofing systems">
        Shingle roofing systems are a popular and versatile choice for
        homeowners due to their durability, affordability, and aesthetic appeal.
        At Noble Assurance Group, we specialize in installing and maintaining
        high-quality shingle roofs that provide excellent protection against the
        elements. Our shingle options come in a variety of styles and colors,
        allowing you to customize the look of your home while ensuring it
        remains resilient to the challenging weather conditions of Louisiana,
        Mississippi, and Texas. With expert installation and comprehensive
        maintenance services, our shingle roofing systems offer a reliable and
        long-lasting solution to keep your home pristine and secure.
      </CommonDescription>
      <SpringBox>
        <CommonSubHeader aria-label="Contact us header">
          Contact us for a free inspection
        </CommonSubHeader>
        <CommonDescription aria-label="Contact our experts to assist with receiving your free inspection">
          Concerned about the condition of your shingle roof? Noble Assurance
          Group offers free, no-obligation inspections to assess the state of
          your roof and recommend the best course of action. Our experienced
          professionals will thoroughly examine your roof for any signs of
          damage or wear, providing you with a detailed report and expert
          advice. Don't wait until minor issues become major problems—contact us
          today to schedule your free shingle roof inspection and ensure your
          home remains protected and beautiful.
        </CommonDescription>
      </SpringBox>
    </CommonRouteContainer>
  );
}
