import { Box } from "@mui/material";
import { groupedSectionGap } from "../../shared/Constants/PaddingConstants";
import CommonDescription from "../../shared/components/Common/CommonDescription";
import CommonHeader from "../../shared/components/Common/CommonHeader";
import CommonSubHeader from "../../shared/components/Common/CommonSubHeader";
import SpringBox from "../../shared/components/SpringBox";
import CommonRouteContainer from "../../shared/components/container/CommonRouteContainer";
import AccreditationLogo from "./AccreditationLogo";

export default function AboutUs() {
  return (
    <CommonRouteContainer
      titlePrefix="About us"
      metaDescription="Welcome to Noble Assurance Group, your trusted partner in general
          contracting"
      aria-label="About us header"
    >
      <Box>
        <CommonHeader aria-label="About us header">About us</CommonHeader>
        <CommonSubHeader aria-label="Subheader">
          Who are we? Why us?
        </CommonSubHeader>
      </Box>
      <SpringBox spring={false}>
        <CommonDescription aria-label="Welcome to noble assurance group">
          Welcome to Noble Assurance Group, your trusted partner in general
          contracting. Serving the vibrant communities of Louisiana,
          Mississippi, and Texas we specialize in delivering high-quality
          construction services tailored to meet the diverse needs of our
          clients.
        </CommonDescription>
        <CommonDescription aria-label="Who we are at noble assurance group">
          At Noble Assurance Group, we are dedicated to excellence in every
          aspect of our work. Our team of skilled professionals brings years of
          experience and a wealth of expertise to every project, ensuring that
          each one is completed with the highest standards of quality and
          craftsmanship. Whether it's a new build, a renovation, or a commercial
          project, we approach every job with the same level of commitment and
          attention to detail.
        </CommonDescription>
        <CommonDescription aria-label="Our dedication to our clients">
          What sets us apart is our unwavering dedication to customer
          satisfaction. We believe that every project is a partnership, and we
          work closely with our clients to understand their vision and bring it
          to life. From initial consultation to project completion, we
          prioritize open communication, transparency, and integrity, ensuring
          that our clients are informed and involved every step of the way.
        </CommonDescription>
        <CommonDescription aria-label="Services we offer">
          Our comprehensive range of services includes residential construction,
          commercial construction, remodeling, and renovations. We leverage the
          latest technologies and industry best practices to deliver innovative
          solutions that meet the unique needs of each client. Our deep
          understanding of local building codes and regulations ensures that
          every project is compliant and built to last.
        </CommonDescription>
        <CommonDescription aria-label="Our company core values">
          At Noble Assurance Group, our core values of trust, excellence, and
          reliability drive everything we do. We are committed to building
          strong, lasting relationships with our clients and delivering projects
          that not only meet but exceed their expectations.
        </CommonDescription>
        <CommonDescription aria-label="What our clients think">
          Join the many satisfied clients who have chosen Noble Assurance Group
          for their construction needs. Let us help you turn your vision into
          reality with unparalleled quality and service.
        </CommonDescription>
      </SpringBox>
      <SpringBox aria-label="States that we server">
        <CommonSubHeader>Serving you and your community</CommonSubHeader>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <img aria-label="Louisiana" src={require("./states/Louisiana.png")} />
          <img
            aria-label="Mississippi"
            src={require("./states/Mississippi.png")}
          />
          <img aria-label="Texas" src={require("./states/Texas.png")} />
        </Box>
      </SpringBox>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: groupedSectionGap,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            gap: groupedSectionGap,
          }}
        >
          <AccreditationLogo
            aria-label="Better Business Bureau"
            src={require("./logos/AB_BBB.png")}
            alt={"Accredited business"}
          />
          <AccreditationLogo
            aria-label="National Roofing Contractors Association"
            src={require("./logos/NRCA.png")}
            alt={"National roofing contractors association"}
          />
          <AccreditationLogo
            aria-label="Select Shingle Master"
            src={require("./logos/SSM.png")}
            alt={"Select shingle master"}
          />
        </Box>
      </Box>
    </CommonRouteContainer>
  );
}
