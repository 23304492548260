import { Box, Typography } from "@mui/material";
import useScalableSizes from "../../shared/hooks/useScalableSizes";

interface TermBoxProps {
  text: string;
  children: React.ReactNode;
}

export default function TermBox({ text, children }: TermBoxProps) {
  const { subHeaderTextSize, descriptionTextSize } = useScalableSizes();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        backgroundColor: "#E7E7E7",
        borderRadius: "16px",
        padding: "25px",
        boxShadow: "4px 6px 4px rgba(0, 0, 0, 0.15)",
        height: "200px",
        width: "250px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Kanit",
          fontSize: subHeaderTextSize,
          color: "#454545",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        {text}
      </Typography>
      <div
        style={{
          fontFamily: "Roboto",
          fontSize: descriptionTextSize,
          color: "#656565",
          paddingLeft: "10px",
        }}
      >
        {children}
      </div>
    </Box>
  );
}
