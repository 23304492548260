import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import {
  footerBackground,
  nobleOrange,
  offWhite,
} from "../../../assets/Colors";
import { smallGroupedSectionGap } from "../../Constants/PaddingConstants";
import useScalableSizes from "../../hooks/useScalableSizes";
import { useState } from "react";

export interface SummaryIconProps {
  icon: any;
  text: string;
  number: number;
}

export default function SummaryIcon({ icon, text, number }: SummaryIconProps) {
  // State
  const [hovered, setHovered] = useState<boolean>(false);

  // Context
  const { subDescriptionTextSize } = useScalableSizes();

  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: smallGroupedSectionGap,
        color: hovered ? footerBackground : "#656565",
      }}
    >
      <Box
        sx={{
          position: "relative",
          top: 30,
          width: "40px",
          height: "40px",
          backgroundColor: nobleOrange,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: offWhite,
          fontSize: "12px",
          fontWeight: "bold",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          {number}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: footerBackground,
          paddingX: "10px",
          height: "150px",
          width: "150px",
          borderRadius: "16px",
          alignItems: "center",
          justifyContent: "center",
          color: offWhite,
          transition: "all 0.3s linear",
          "&:hover": {
            color: nobleOrange,
          },
          gap: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "end",
          }}
          flex={1}
        >
          <FontAwesomeIcon icon={icon} color="inherit" size="2x" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
          }}
          flex={1}
        >
          <Typography
            sx={{
              color: "#B8B8B8",
              fontSize: subDescriptionTextSize,
              fontFamily: "Raleway",
              textAlign: "center",
              transition: "color 0.3 linear",
            }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
