import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BackToTop from "../../../components/back-to-top/BackToTop";
import Footer from "../../../components/footer/Footer";
import { useDocumentTitle } from "../../context/DocumentTitleContext";
import useScalableSizes from "../../hooks/useScalableSizes";

interface CommonRouteContainerProps {
  children?: React.ReactNode;
  titlePrefix?: string;
  display?: string;
  width?: string;
  metaDescription: string;
}

export default function CommonRouteContainer({
  children,
  titlePrefix,
  display = "flex",
  width,
  metaDescription,
}: CommonRouteContainerProps) {
  // Constants
  const fadeInDuration = 450;
  const fadeInTimeout = 10;

  // State
  const [fadeInCompleted, setFadeInCompleted] = useState<boolean>(false);

  // Context
  const { setDocumentTitlePrefix } = useDocumentTitle();
  const { primaryContainerWidth, isLargerThanSe } = useScalableSizes();

  // Effects
  useEffect(() => {
    if (titlePrefix) setDocumentTitlePrefix(titlePrefix);
  }, [titlePrefix, setDocumentTitlePrefix]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setFadeInCompleted(true), fadeInTimeout);
    return () => {
      clearTimeout(timeoutId);
      setFadeInCompleted(false);
    };
  }, [fadeInDuration]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        alignItems: "center",
      }}
    >
      <Helmet>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Box
        sx={{
          display: display,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: width ?? primaryContainerWidth,
          gap: isLargerThanSe ? "70px" : "50px",
          transition: `opacity ${fadeInDuration}ms ease-in-out`,
          opacity: fadeInCompleted ? 1 : 0,
          flex: 1,
          paddingY: "50px",
        }}
      >
        <BackToTop />
        {children}
      </Box>
      <Footer />
    </Box>
  );
}
