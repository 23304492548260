import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { footerBackground, nobleOrange } from "../../assets/Colors";
import { smallGroupedSectionGap } from "../../shared/Constants/PaddingConstants";

interface ContactCardProps {
  icon: any;
  link: string;
}

export default function ContactCard({ icon, link }: ContactCardProps) {
  return (
    <Link to={link} target="_blank">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: smallGroupedSectionGap,
          backgroundColor: footerBackground,
          borderRadius: "16px",
          width: "75px",
          height: "75px",
          color: "white",
          transition: "all 0.15s linear",
          "&:hover": {
            color: nobleOrange,
          },
        }}
      >
        <FontAwesomeIcon icon={icon} color={"inherit"} size="2x" />
      </Box>
    </Link>
  );
}
