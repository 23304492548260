import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import { useAlert } from "../../shared/context/AlertContext";

interface Props {
  children?: React.ReactNode;
  videoSource: any;
  blur?: number;
  borderRadius?: number;
}

export default function CommonVideo({
  children,
  videoSource,
  blur = 4,
  borderRadius = 16,
}: Props) {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        maxWidth: "800px",
        borderRadius: `${borderRadius}px`,
        overflow: "hidden",
      }}
    >
      <Box
        component="video"
        src={videoSource}
        loop
        autoPlay
        muted
        playsInline
        preload="auto"
        sx={{
          width: "100%",
          height: "auto",
          borderRadius: `${borderRadius}px`,
        }}
      />
      {children && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backdropFilter: `blur(${blur}px)`,
            borderRadius: `${borderRadius}px`,
            pointerEvents: "none",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}
