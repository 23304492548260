import { Box, Button, lighten } from "@mui/material";
import { Link } from "react-router-dom";
import { footerBackground, offWhite } from "../../assets/Colors";
import CommonHeader from "../../shared/components/Common/CommonHeader";
import CommonSubHeader from "../../shared/components/Common/CommonSubHeader";
import CommonRouteContainer from "../../shared/components/container/CommonRouteContainer";

export default function NotFound() {
  return (
    <CommonRouteContainer
      titlePrefix="Not found"
      metaDescription="404 - not found"
    >
      <img
        alt="Delapidated house"
        style={{
          maxWidth: "80%",
        }}
        src={require("./House.png")}
        aria-label="404 house image"
      />
      <Box>
        <CommonHeader aria-label="not found description">
          Oop! Looks like this page is falling apart...
        </CommonHeader>
        <CommonSubHeader>
          But don't worry, your roof doesn't have to!
        </CommonSubHeader>
      </Box>
      <Link to="/home">
        <Button
          aria-label="Return home button"
          variant="contained"
          sx={{
            textTransform: "none",
            fontFamily: "Raleway",
            fontSize: "16px",
            backgroundColor: footerBackground,
            color: offWhite,
            fontWeight: "bold",
            borderRadius: "200px",
            "&:hover": {
              backgroundColor: lighten(footerBackground, 0.2),
            },
            width: "180px",
          }}
        >
          Find out how
        </Button>
      </Link>
    </CommonRouteContainer>
  );
}
