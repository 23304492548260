import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import CommonHeader from "./CommonHeader";
import CommonSubHeader from "./CommonSubHeader";

interface CommonRouteImageWithTextHeaderProps {
  image?: any;
  video?: any;
  alt: string;
  header: string;
  subHeader: string;
}

export default function CommonRouteImageWithTextHeader({
  image,
  video,
  alt,
  header,
  subHeader,
}: CommonRouteImageWithTextHeaderProps) {
  // Refs
  const videoRef = useRef<HTMLVideoElement>(null);

  // Effects
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;
    video.muted = true;
    video.playsInline = true;
    video.play().catch(() => (video.controls = true));
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100vw",
        height: { xs: "40vh", md: image ? "40vh" : "60vh" },
        overflow: "hidden",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(to top, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.2))",
          zIndex: 1,
        },
      }}
    >
      {image && (
        <img
          src={image}
          alt={alt}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "relative",
            zIndex: 0,
          }}
        />
      )}
      {video && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "relative",
            zIndex: 0,
          }}
        >
          <Box
            ref={videoRef}
            component="video"
            src={require("../../../components/home/HeritageParkBounce.mp4")}
            loop
            autoPlay
            muted
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "relative",
              zIndex: 0,
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "white",
          textAlign: "center",
          zIndex: 2,
          width: "100%",
          paddingX: "5%",
        }}
      >
        <CommonHeader color="white">{header}</CommonHeader>
        <CommonSubHeader color="#D8D8D8">{subHeader}</CommonSubHeader>
      </Box>
    </Box>
  );
}
