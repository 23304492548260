import { Box } from "@mui/material";
import { nobleRed } from "../../assets/Colors";
import CommonDescription from "../../shared/components/Common/CommonDescription";
import CommonHeader from "../../shared/components/Common/CommonHeader";
import CommonRouteImageWithTextHeader from "../../shared/components/Common/CommonRouteImageWithTextHeader";
import CommonRouteContainer from "../../shared/components/container/CommonRouteContainer";
import { groupedSectionGap } from "../../shared/Constants/PaddingConstants";
import { useMinWidth } from "../../shared/hooks/useMinWidth";
import RepairVsReplacementBox from "./RepairVsReplacementBox";

export default function RepairVsReplacement() {
  // Constants
  const canShowRow = useMinWidth(800);

  return (
    <CommonRouteContainer
      titlePrefix="Repair vs replacement"
      metaDescription="Repair vs. replacement of a roofing system in Louisiana: what you need
          to know"
    >
      <CommonRouteImageWithTextHeader
        image={require("./RoofRepair.jpg")}
        alt={"Repair vs replacement"}
        header={"Repair vs replacement"}
        subHeader={"Make an informed decision"}
        aria-label="Repair vs replacement header and image"
      />
      <CommonHeader aria-label="repair vs replacement header">
        Repair vs. replacement of a roofing system in Louisiana: what you need
        to know
      </CommonHeader>
      <CommonDescription aria-label="Overall summary">
        In the vibrant and weather-challenged state of Louisiana, maintaining a
        robust roofing system is crucial for protecting your home. When faced
        with roofing issues, homeowners often grapple with the decision between
        repair and replacement. Understanding the key factors can help you make
        an informed choice.
      </CommonDescription>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: groupedSectionGap,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: canShowRow ? "row" : "column",
            alignItems: "center",
            justifyContent: "center",
            gap: groupedSectionGap,
          }}
        >
          <RepairVsReplacementBox
            header={"Repair"}
            aria-label="Repair definition and terms"
          >
            Repairing your roof is often a cost-effective and timely solution
            for minor damages such as small leaks, missing shingles, or
            localized wear and tear. In Louisiana's climate, where storms and
            heavy rains are common, timely repairs can prevent further damage
            and extend the life of your roof. It's ideal for newer roofs or
            those in generally good condition with isolated issues.
          </RepairVsReplacementBox>
          <RepairVsReplacementBox
            header={"Replacement"}
            aria-label="Replacement definition and terms"
          >
            A full roof replacement becomes necessary when the damage is
            extensive or the roof has reached the end of its lifespan, typically
            around 20-25 years for asphalt shingles. In Louisiana, frequent
            severe weather can accelerate wear and tear, making regular
            inspections critical. Replacement offers the opportunity to upgrade
            materials, improve energy efficiency, and ensure long-term
            protection against the elements.
          </RepairVsReplacementBox>
        </Box>
        <RepairVsReplacementBox
          header={"Making the decision"}
          headerColor={nobleRed}
          aria-label="How to choose between repair and replacement"
        >
          Consulting with a professional roofing contractor is essential. They
          can assess the extent of the damage, the age of your roof, and the
          best course of action. In some cases, what appears to be minor damage
          may reveal underlying issues that warrant a full replacement.
        </RepairVsReplacementBox>
      </Box>
      <CommonDescription aria-label="Contact us to learn more">
        At Noble Assurance Group, we provide expert assessments and honest
        recommendations to help you make the best decision for your home.
        Whether it's a repair or a replacement, our goal is to ensure your
        roofing system is resilient, reliable, and ready to withstand
        Louisiana's unique climate challenges.
      </CommonDescription>
    </CommonRouteContainer>
  );
}
