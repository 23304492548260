import { Box, Drawer } from "@mui/material";
import Hamburger from "hamburger-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { offBlack } from "../../assets/Colors";
import {
  navbarItemGap,
  navbarPadding,
} from "../../shared/Constants/PaddingConstants";
import { useMinWidth } from "../../shared/hooks/useMinWidth";
import FullTabItem from "./FullTabItem";
import { tabs } from "./Tabs";

export default function Navbar() {
  // Constants
  const menuTransitionPoint = 1230;
  const minOpacity = 0.1;

  // State
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [expandedParent, setExpandedParent] = useState<string | null>(null);

  // Hooks
  const canShowFullMenu = useMinWidth(menuTransitionPoint);

  // Effects
  useEffect(() => setMenuOpen(false), [canShowFullMenu]);

  const logo = (
    <Link
      aria-label="Noble assurance group logo"
      to="/home"
      style={{
        flex: 1,
        paddingTop: "10px",
      }}
    >
      <img
        alt="Noble Assurance Group logo"
        src={require("../../assets/logo.png")}
        style={{
          height: "80px",
        }}
      />
    </Link>
  );

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  const menuButton = (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "end",
      }}
    >
      <Hamburger
        aria-label="Menu button"
        size={26}
        duration={0.3}
        color={offBlack}
        toggled={menuOpen}
        toggle={toggleMenu}
        hideOutline
        rounded
      />
    </Box>
  );

  const menu = (
    <Drawer
      anchor="right"
      open={menuOpen}
      onClose={() => setMenuOpen(false)}
      PaperProps={{
        sx: {
          background: "rgba(255, 255, 255, 0.8)",
          backdropFilter: "blur(8px)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Hamburger
          size={26}
          duration={0.3}
          color={offBlack}
          toggled={menuOpen}
          toggle={toggleMenu}
          hideOutline
          rounded
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          margin: "0 auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            height: "100%",
            paddingBottom: "20px",
            paddingX: "10px",
            gap: "5px",
          }}
        >
          {tabs
            .sort((a, b) => a.index - b.index)
            .filter((tab) => !tab.disabled)
            .map((tab) => (
              <FullTabItem
                key={tab.name}
                tab={tab}
                smallMode
                setMenuOpen={setMenuOpen}
                expandedParent={expandedParent}
                setExpandedParent={setExpandedParent}
              />
            ))}
        </Box>
      </Box>
    </Drawer>
  );

  function getNavbarOpacity() {
    return minOpacity;
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100px",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        paddingX: navbarPadding,
        position: "sticky",
        top: 0,
        zIndex: 1000,
        background: "rgba(255, 255, 255, 0.5)",
        backdropFilter: "blur(8px)",
        borderBottom: "1px solid rgba(255, 255, 255, 0.15)",
        "&::after": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `rgba(255, 255, 255, ${getNavbarOpacity()})`,
          pointerEvents: "none",
          transition: "all 0.3s ease",
        },
      }}
    >
      {logo}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          justifyContent: "end",
          gap: navbarItemGap,
          flex: !canShowFullMenu ? 1 : "auto",
        }}
      >
        {!canShowFullMenu && menuButton}
        {canShowFullMenu &&
          tabs
            .sort((a, b) => a.index - b.index)
            .filter((tab) => !tab.disabled)
            .map((tab) => (
              <FullTabItem
                aria-label={`${tab.name} menu item`}
                key={tab.name}
                tab={tab}
                expandedParent={expandedParent}
                setExpandedParent={setExpandedParent}
              />
            ))}
      </Box>
      {menu}
    </Box>
  );
}
