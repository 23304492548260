import { faClipboard, faLightbulb } from "@fortawesome/free-regular-svg-icons";
import {
  faShield,
  faCloud,
  faMagnifyingGlass,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import CommonDescription from "../../../shared/components/Common/CommonDescription";
import CommonRouteImageWithTextHeader from "../../../shared/components/Common/CommonRouteImageWithTextHeader";
import CommonRouteContainer from "../../../shared/components/container/CommonRouteContainer";
import SpringBox from "../../../shared/components/SpringBox";
import SummaryIconContainer from "../../../shared/components/Summary/SummaryIconContainer";

export default function InsuranceClaims() {
  return (
    <CommonRouteContainer
      titlePrefix="Insurance claims"
      metaDescription="At Noble Assurance Group, we specialize in navigating insurance claims
          to ensure homeowners receive the support they need for their roofing
          needs"
    >
      <CommonRouteImageWithTextHeader
        image={require("./InsuranceClaims.png")}
        alt={"Insurance claims"}
        header={"Insurance claims"}
        subHeader={"We know it can be a headache, but we're here to help"}
        aria-label="Insurance claims header image"
      />
      <SummaryIconContainer
        aria-label="Summary icons"
        icons={[
          {
            icon: faClipboard,
            text: "Streamlined claims process",
          },
          {
            icon: faMagnifyingGlass,
            text: "Detailed roof assessments",
          },
          {
            icon: faUserTie,
            text: "Licensed and certified support",
          },
        ]}
      />
      <SpringBox>
        <CommonDescription aria-label="Specialization in handling insurance claims">
          At Noble Assurance Group, we specialize in navigating insurance claims
          to ensure homeowners receive the support they need for their roofing
          needs. We understand the complexities of the claims process and work
          diligently to streamline it for our clients. Whether you're dealing
          with storm damage, leaks, or other roofing issues, our experienced
          team is here to assist you every step of the way.
        </CommonDescription>
        <CommonDescription aria-label="What we provide to assist you during this process">
          We provide thorough inspections, detailed assessments, and expert
          guidance to help you file your claim accurately and efficiently. Our
          team holds multiple certifications and licenses, ensuring we can
          effectively communicate with your insurer and advocate for your best
          interests. Trust Noble Assurance Group to restore your home with
          minimal stress and hassle. Contact us today to learn more about how we
          can assist you with your insurance claim and schedule a free
          consultation.
        </CommonDescription>
      </SpringBox>
    </CommonRouteContainer>
  );
}
