import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/material";
import CommonRouteImageWithTextHeader from "../../shared/components/Common/CommonRouteImageWithTextHeader";
import CommonSubHeader from "../../shared/components/Common/CommonSubHeader";
import CommonRouteContainer from "../../shared/components/container/CommonRouteContainer";
import SpringBox from "../../shared/components/SpringBox";
import {
  groupedSectionGap,
  groupedSectionPadding,
  largeGroupedSectionGap,
  smallGroupedSectionGap,
} from "../../shared/Constants/PaddingConstants";
import BeSureToIncludeRow from "./BeSureToIncludeRow";
import ContactCard from "./ContactCard";
import {
  contactEmail,
  contactFacebook,
  contactInstagram,
  contactNumber,
} from "./ContactConstants";
import ContactForm from "./ContactForm";

export default function Contact() {
  return (
    <CommonRouteContainer
      titlePrefix="Contact"
      metaDescription="Contact us via the form, emailing us, or on social media"
    >
      <CommonRouteImageWithTextHeader
        image={require("./Contact.jpg")}
        alt={"Contact us"}
        header={"Contact"}
        subHeader={"We're looking forward to hearing from you"}
        aria-label="Contact us header and image"
      />
      <SpringBox>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: groupedSectionGap,
            alignItems: "start",
          }}
        >
          <CommonSubHeader>
            Contact us using the form below. Be sure to include...
          </CommonSubHeader>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: smallGroupedSectionGap,
              paddingLeft: largeGroupedSectionGap,
              alignItems: "start",
            }}
            aria-label="Information to include in your contact"
          >
            <BeSureToIncludeRow text={"Home size"} number={1} />
            <BeSureToIncludeRow text={"Home location"} number={2} />
            <BeSureToIncludeRow text={"Home age"} number={3} />
            <BeSureToIncludeRow text={"Requested services"} number={4} />
          </Box>
        </Box>
      </SpringBox>
      <SpringBox>
        <ContactForm />
      </SpringBox>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: groupedSectionGap,
        }}
      >
        <CommonSubHeader>Other contact methods</CommonSubHeader>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            gap: groupedSectionGap,
          }}
          aria-label="Social media container"
        >
          <ContactCard
            icon={faFacebook}
            link={contactFacebook}
            aria-label="Facebook"
          />
          <ContactCard
            icon={faInstagram}
            link={contactInstagram}
            aria-label="Instagram"
          />
          <ContactCard
            icon={faPhone}
            link={`tel:${contactNumber}`}
            aria-label="Phone number"
          />
          <ContactCard
            icon={faEnvelope}
            link={`mailto:${contactEmail}`}
            aria-label="Envelope"
          />
        </Box>
      </Box>
    </CommonRouteContainer>
  );
}
