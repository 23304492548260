import { Box, Button, lighten } from "@mui/material";
import { useRef } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { footerBackground, nobleOrange, offWhite } from "../../assets/Colors";
import {
  setEmailAddress,
  setFullName,
  setMessage,
  setPhoneNumber,
  setPreferredName,
} from "../../redux/contactFormSlice";
import { useAppDispatch, useAppSelector } from "../../redux/ReduxHooks";
import CommonSubHeader from "../../shared/components/Common/CommonSubHeader";
import { CommonReduxField } from "../../shared/components/container/CommonReduxField";
import CaptchaBadge from "../../shared/components/Google/CaptchaBadge";
import { smallGroupedSectionGap } from "../../shared/Constants/PaddingConstants";
import {
  emailAddressRegex,
  phoneNumberRegex,
} from "../../shared/Constants/Regex";
import { useAlert } from "../../shared/context/AlertContext";
import { sendContactForm } from "../../shared/Mailchimp/MailchimpService";
import { Link } from "react-router-dom";
import { contactForm } from "../../shared/Constants/FormConstants";
import LaunchIcon from "@mui/icons-material/Launch";

// todo Google reviews section
// todo testimonials from customers

export default function ContactForm() {
  // Refs
  const fieldRefs = [
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
  ];

  // Redux
  const fullName = useAppSelector((state) => state.contactForm.fullName);
  const preferredName = useAppSelector(
    (state) => state.contactForm.preferredName
  );
  const emailAddress = useAppSelector(
    (state) => state.contactForm.emailAddress
  );
  const phoneNumber = useAppSelector((state) => state.contactForm.phoneNumber);
  const message = useAppSelector((state) => state.contactForm.message);

  const dispatch = useAppDispatch();

  // Context
  const { showInfoAlert, showErrorAlert } = useAlert();

  // Hooks
  const { executeRecaptcha } = useGoogleReCaptcha();

  async function onSubmitClicked() {
    if (!executeRecaptcha) return;
    // todo use in body to Mailchimp
    const gcaptcha = await executeRecaptcha();

    fieldRefs.forEach((ref) => {
      if (ref.current) {
        ref.current.touch();
        ref.current.triggerHelperTextEvaluation();
      }
    });

    if (getFullNameHelperText(fullName)) return;
    if (getPreferredNameHelperText(preferredName)) return;
    if (getEmailAddressHelperText(emailAddress)) return;
    if (getPhoneNumberHelperText(phoneNumber)) return;
    if (getMessageHelperText(message)) return;

    sendContactForm(
      fullName,
      preferredName,
      emailAddress,
      phoneNumber,
      message,
      gcaptcha,
      onContactFormSubmitSuccess,
      onContactFormSubmitFailure
    );
  }

  function onContactFormSubmitSuccess() {
    showInfoAlert("Contact form submitted");

    dispatch(setFullName(""));
    dispatch(setPreferredName(""));
    dispatch(setEmailAddress(""));
    dispatch(setPhoneNumber(""));
    dispatch(setMessage(""));

    fieldRefs.forEach((ref) => {
      if (ref.current) {
        ref.current.untouch();
      }
    });
  }

  function onContactFormSubmitFailure() {
    showErrorAlert("Failed to submit; please fill out the manual form", {
      icon: (
        <Link target="_blank" to={contactForm}>
          <LaunchIcon />
        </Link>
      ),
    });
  }

  function getFullNameHelperText(value: string) {
    if (!value || !value.trim()) return "Full name required";
    else return "";
  }

  function getPreferredNameHelperText(value: string) {
    if (!value || !value.trim()) return "Preferred name required";
    else return "";
  }

  function getEmailAddressHelperText(value: string) {
    if (!value || !value.trim()) return "Email required";
    else if (!emailAddressRegex.test(value)) return "Invalid email address";
    else return "";
  }

  function getPhoneNumberHelperText(value: string) {
    if (!value || !value.trim()) return "Phone number required";
    else if (!phoneNumberRegex.test(value)) return "Invalid phone number";
    else return "";
  }

  function getMessageHelperText(value: string) {
    if (!value || !value.trim()) return "Message required";
    else return "";
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: smallGroupedSectionGap,
      }}
    >
      <CommonSubHeader>Contact form</CommonSubHeader>
      <CommonReduxField
        ref={fieldRefs[0]}
        label={"Full name"}
        value={fullName}
        setter={setFullName}
        getHelperText={getFullNameHelperText}
      />
      <CommonReduxField
        ref={fieldRefs[1]}
        label={"Preferred name"}
        value={preferredName}
        setter={setPreferredName}
        getHelperText={getPreferredNameHelperText}
      />
      <CommonReduxField
        ref={fieldRefs[2]}
        label={"Your email"}
        value={emailAddress}
        setter={setEmailAddress}
        getHelperText={getEmailAddressHelperText}
      />
      <CommonReduxField
        ref={fieldRefs[3]}
        label={"Your phone number"}
        value={phoneNumber}
        setter={setPhoneNumber}
        getHelperText={getPhoneNumberHelperText}
      />
      <CommonReduxField
        ref={fieldRefs[4]}
        multiline
        label={"Message"}
        value={message}
        setter={setMessage}
        getHelperText={getMessageHelperText}
      />
      <Button
        variant="contained"
        fullWidth
        onClick={onSubmitClicked}
        sx={{
          textTransform: "none",
          fontFamily: "Raleway",
          fontSize: "16px",
          backgroundColor: footerBackground,
          color: offWhite,
          fontWeight: "bold",
          transition: "all 0.15s linear",
          "&:hover": {
            backgroundColor: lighten(nobleOrange, 0.2),
            color: footerBackground,
          },
        }}
      >
        Submit
      </Button>
      <CaptchaBadge />
    </Box>
  );
}
