import {
  maxSeScrenWidth,
  maxSmallScreenWidth,
  mediumScreenWidth,
} from "./../../assets/CommonConstants";

import { useMinWidth } from "./useMinWidth";

export default function useScalableSizes() {
  const isLargeScreen = useMinWidth(maxSmallScreenWidth);
  const isMediumScreen = useMinWidth(mediumScreenWidth);
  const isLargerThanSe = useMinWidth(maxSeScrenWidth);

  const descriptionTextSize = isLargeScreen ? "16px" : "14px";
  const disclaimerTextSize = isLargeScreen ? "14px" : "11px";
  const subDescriptionTextSize = isMediumScreen ? "14px" : "12px";
  const headerTextSize = isLargeScreen ? "42px" : "32px";
  const subHeaderTextSize = isLargeScreen ? "20px" : "17px";
  const subSubHeaderTextSize = isLargeScreen ? "18px" : "12px";
  const primaryContainerWidth = isLargeScreen ? "69%" : "90%";
  const modalHeaderFontSize = isLargeScreen ? "46px" : "34px";
  const commonContainerSize = useMinWidth(480) ? "300px" : "200px";

  return {
    descriptionTextSize,
    disclaimerTextSize,
    headerTextSize,
    subHeaderTextSize,
    subSubHeaderTextSize,
    primaryContainerWidth,
    isLargeScreen,
    isLargerThanSe,
    isMediumScreen,
    modalHeaderFontSize,
    commonContainerSize,
    subDescriptionTextSize,
  };
}
