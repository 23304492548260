import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import AppContextAndWrappers from "./app/AppContextsAndWrappers";
import "./index.css";

const htmlRoot = document.getElementById("root");
if (!htmlRoot) throw Error("No root element found");
const reactRoot = ReactDOM.createRoot(htmlRoot);

reactRoot.render(
  <StrictMode>
    <BrowserRouter>
      <AppContextAndWrappers />
    </BrowserRouter>
  </StrictMode>
);
