import {
  faHouse,
  faLock,
  faPaintBrush,
  faPaintbrush,
  faPaintRoller,
} from "@fortawesome/free-solid-svg-icons";
import CommonDescription from "../../../shared/components/Common/CommonDescription";
import CommonRouteImageWithTextHeader from "../../../shared/components/Common/CommonRouteImageWithTextHeader";
import CommonRouteContainer from "../../../shared/components/container/CommonRouteContainer";
import SpringBox from "../../../shared/components/SpringBox";
import SummaryIconContainer from "../../../shared/components/Summary/SummaryIconContainer";

export default function Fencing() {
  return (
    <CommonRouteContainer
      titlePrefix="Fencing"
      metaDescription="Noble Assurance Group offers a wide range of fencing services to
          enhance the security, privacy, and aesthetic appeal of your property"
    >
      <CommonRouteImageWithTextHeader
        image={require("./Fencing.jpg")}
        alt={"Fencing"}
        header={"Fencing"}
        subHeader={"Ehance your property's aesthetic"}
        aria-label="Fencing image and header"
      />
      <SummaryIconContainer
        aria-label="Summary icons"
        icons={[
          {
            icon: faLock,
            text: "Secure and private",
          },
          {
            icon: faHouse,
            text: "Enhanced property",
          },
          {
            icon: faPaintRoller,
            text: "Custom style and finish",
          },
        ]}
      />
      <SpringBox>
        <CommonDescription aria-label="What we offer in terms of fencing services">
          Noble Assurance Group offers a wide range of fencing services to
          enhance the security, privacy, and aesthetic appeal of your property.
          Whether you're looking to install a new fence or repair an existing
          one, our experienced team provides professional and reliable solutions
          tailored to meet your specific needs.
        </CommonDescription>
        <CommonDescription aria-label="What our services are designed to do and the options we provide">
          Our custom fence installation services are designed to suit a variety
          of residential and commercial applications. We offer a selection of
          materials, including wood, vinyl, metal, and composite, allowing you
          to choose the perfect style and finish for your property. From classic
          picket fences to modern privacy barriers, we ensure your fence
          complements your landscape and enhances your property's overall
          appearance.
        </CommonDescription>
      </SpringBox>
    </CommonRouteContainer>
  );
}
