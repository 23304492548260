import { Typography } from "@mui/material";
import useScalableSizes from "../../hooks/useScalableSizes";

interface CommonSubHeaderProps {
  children: React.ReactNode;
  color?: string;
}

export default function CommonSubHeader({
  children,
  color = "#454545",
}: CommonSubHeaderProps) {
  const { subHeaderTextSize } = useScalableSizes();

  return (
    <Typography
      sx={{
        fontFamily: "Kanit",
        fontSize: subHeaderTextSize,
        color: color,
        textAlign: "center",
        fontWeight: "bold",
      }}
    >
      {children}
    </Typography>
  );
}
