import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { faLock, faUmbrella } from "@fortawesome/free-solid-svg-icons";
import CommonDescription from "../../../shared/components/Common/CommonDescription";
import CommonRouteImageWithTextHeader from "../../../shared/components/Common/CommonRouteImageWithTextHeader";
import CommonRouteContainer from "../../../shared/components/container/CommonRouteContainer";
import SpringBox from "../../../shared/components/SpringBox";
import SummaryIconContainer from "../../../shared/components/Summary/SummaryIconContainer";

export default function CommercialMetalRoofing() {
  return (
    <CommonRouteContainer
      titlePrefix="Metal roofing"
      metaDescription="Commercial metal roofing is a robust and versatile choice for
          businesses seeking durability, energy efficiency"
    >
      <CommonRouteImageWithTextHeader
        image={require("./CommercialMetalRoofing.jpg")}
        alt={"Metal roofing"}
        header={"Metal roofing"}
        subHeader={"Longevity with minimal maintenance"}
        aria-label="Commercial metal roofing header image"
      />
      <SummaryIconContainer
        aria-label="Summary icons"
        icons={[
          {
            icon: faUmbrella,
            text: "Long lasting protection",
          },
          {
            icon: faBuilding,
            text: "Tailored for business",
          },
          {
            icon: faLock,
            text: "Resilient and secure",
          },
        ]}
      />
      <CommonDescription aria-label="What we offer and what it preserves against">
        Commercial metal roofing is a robust and versatile choice for businesses
        seeking durability, energy efficiency, and aesthetic appeal. At Noble
        Assurance Group, we specialize in installing and maintaining
        high-quality metal roofing systems tailored to the unique needs of
        commercial properties. Metal roofs offer exceptional longevity and
        require minimal maintenance, making them a cost-effective investment for
        businesses. They provide superior protection against extreme weather
        conditions, including wind, rain, and hail, while also offering
        excellent resistance to fire and pests.
      </CommonDescription>
      <SpringBox>
        <CommonDescription aria-label="How we can help and how it improves your assets">
          Our team of experienced professionals ensures precise installation and
          craftsmanship, ensuring your commercial property not only looks
          impressive but also remains secure and weather-resistant for years to
          come. With a variety of styles, finishes, and colors available, we can
          customize your metal roof to complement your building's architecture
          and enhance its curb appeal. Trust Noble Assurance Group for reliable
          commercial metal roofing solutions that deliver performance and
          durability you can depend on. Contact us today to learn more about our
          commercial metal roofing services and schedule a consultation with one
          of our experts.
        </CommonDescription>
      </SpringBox>
    </CommonRouteContainer>
  );
}
