export const smallGroupedSectionGap = "10px";
export const groupedSectionGap = "20px";
export const largeGroupedSectionGap = "50px";

export const navbarItemGap = "5px";

export const smallGroupedSectionPadding = "25px";
export const groupedSectionPadding = "50px";
export const navbarPadding = "10px";
export const footerPadding = "20px";
