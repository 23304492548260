import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Collapse, Grow, Popover } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useLocation } from "react-router-dom";
import { footerBackground, nobleRed } from "../../assets/Colors";
import { Tab } from "./Tab";

interface FullTabItemProps {
  tab: Tab;
  parentRoute?: Tab;
  smallMode?: boolean;
  setMenuOpen?: (open: boolean) => void;
  expandedParent: string | null;
  setExpandedParent: (parent: string | null) => void;
}

export default function FullTabItem({
  tab,
  parentRoute,
  smallMode = false,
  setMenuOpen,
  expandedParent,
  setExpandedParent,
}: FullTabItemProps) {
  // Constants
  const isExpanded = expandedParent === tab.name;

  // State
  const [anchorElement, setAnchorElement] = useState<undefined | HTMLElement>(
    undefined
  );

  // Hooks
  const location = useLocation();

  // Memos
  const onThisTab = useMemo(() => {
    let route = tab.route;
    if (parentRoute) route = parentRoute.route + "/" + route;
    return location.pathname.includes(route);
  }, [parentRoute, tab, location]);

  // Effects
  useEffect(() => {
    closeMenu();
    if (!onThisTab) return;
    setExpandedParent(tab.name);
  }, [location]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!tab.isParent) {
      setMenuOpen?.(false);
      return;
    }

    if (!smallMode) {
      setAnchorElement(anchorElement ? undefined : event.currentTarget);
    } else {
      setExpandedParent(isExpanded ? null : tab.name);
    }
  };

  function closeMenu() {
    setAnchorElement(undefined);
  }

  function shouldShowSelected() {
    if (smallMode && tab.children) return false;
    return onThisTab;
  }

  const button = (
    <Button
      fullWidth
      onClick={handleClick}
      sx={{
        cursor: "pointer",
        textTransform: "none",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: shouldShowSelected() ? "bold" : "normal",
        color: shouldShowSelected() ? nobleRed : footerBackground,
        backgroundColor: shouldShowSelected() ? "#DFDFDF8F" : "inherit",
        borderRadius: "8px",
        transition: "all 0.15s linear",
        width: !!parentRoute || smallMode ? "100%" : "auto",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      {tab.name}
      {tab.isParent && (
        <KeyboardArrowDownIcon
          sx={{
            transform:
              anchorElement || isExpanded ? "rotate(180deg)" : "inherit",
            transition: "all 0.15s linear",
          }}
        />
      )}
    </Button>
  );

  if (!tab.isParent) {
    return (
      <Link
        to={`./${parentRoute ? parentRoute.route + "/" : ""}${tab.route}`}
        key={tab.route}
        style={{
          width: !!parentRoute || smallMode ? "100%" : "auto",
          alignItems: "start",
        }}
      >
        {button}
      </Link>
    );
  }

  const childrenDiv = (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: smallMode ? "auto" : "10px",
        gap: "5px",
      }}
    >
      {tab.children &&
        tab.children
          .filter((tab) => !tab.disabled)
          .map((child) => (
            <FullTabItem
              aria-label={`${child.name} child menu item of ${tab.name}`}
              tab={child}
              parentRoute={tab}
              key={tab.route + "/" + child.route}
              smallMode={smallMode}
              setMenuOpen={setMenuOpen}
              expandedParent={expandedParent}
              setExpandedParent={setExpandedParent}
            />
          ))}
    </div>
  );

  const popover = (
    <Popover
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "12px",
        },
      }}
      TransitionComponent={Grow}
    >
      {childrenDiv}
    </Popover>
  );

  return (
    <>
      {button}
      {smallMode && (
        <Collapse
          in={smallMode && isExpanded}
          timeout={500}
          sx={{
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                height: "4px",
                backgroundColor: "#989898",
                width: "100%",
                borderRadius: "10px",
                marginBottom: "5px",
              }}
            />
            {childrenDiv}
            <Box
              sx={{
                height: "4px",
                backgroundColor: "#989898",
                width: "100%",
                borderRadius: "10px",
                marginTop: "5px",
              }}
            />
          </div>
        </Collapse>
      )}
      {popover}
    </>
  );
}
