import { Box, CircularProgress } from "@mui/material";
import { useRowColumn } from "../../shared/hooks/useRowColumn";
import CommonHeader from "../../shared/components/Common/CommonHeader";
import CommonDescription from "../../shared/components/Common/CommonDescription";
import { useState, useEffect } from "react";
import {
  groupedSectionGap,
  smallGroupedSectionGap,
} from "../../shared/Constants/PaddingConstants";

/**
 * The persistence loading screen to show if the Redux store has been loading for more than two seconds.
 */
export default function PersistGateLoading() {
  // Constants
  const showDelaySeconds = 2;

  // State
  const [showLoader, setShowLoader] = useState(false);

  // Hooks
  const canShowRow = useRowColumn(500);

  // Effects
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(true);
    }, showDelaySeconds * 1000);

    return () => clearTimeout(timer);
  }, []);

  if (!showLoader) {
    return <></>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: groupedSectionGap,
        }}
      >
        <CommonHeader>Getting things ready for you</CommonHeader>
        <Box
          sx={{
            display: "flex",
            flexDirection: canShowRow,
            gap: smallGroupedSectionGap,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={30} />
          <CommonDescription>Loading...</CommonDescription>
        </Box>
      </Box>
    </Box>
  );
}
