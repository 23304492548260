import { useMinWidth } from "../../shared/hooks/useMinWidth";
import CommonVideo from "./CommonVideo";
import ExperiencedAndProfessional from "./ExperiencedAndProfessional";

export default function NewOrleansVideo() {
  const canShowCards = useMinWidth(750);

  if (!canShowCards) return <ExperiencedAndProfessional />;

  return (
    <CommonVideo videoSource={require("./NewOrleans.mp4")}>
      <ExperiencedAndProfessional />
    </CommonVideo>
  );
}
