import { Tab } from "./Tab";

/**
 * The primary navbar tabs shown on NobleAssuranceGroup.com.
 */
export const tabs: Tab[] = [
  {
    name: "Home",
    index: 0,
    route: "home",
  },
  {
    name: "About us",
    index: 1,
    route: "about-us",
  },
  {
    name: "Residential roofing",
    index: 2,
    route: "residential-roofing",
    isParent: true,
    children: [
      {
        name: "Shingle roofing",
        index: 0,
        route: "shingle-roofing",
      },
      {
        name: "Metal roofing",
        index: 1,
        route: "metal-roofing",
      },
      {
        name: "Insurance claims",
        index: 2,
        route: "insurance-claims",
      },
      {
        name: "Historical roofing",
        index: 3,
        route: "historical-roofing",
      },
    ],
  },
  {
    name: "Commercial roofing",
    index: 3,
    route: "commercial-roofing",
    isParent: true,
    children: [
      {
        name: "TPO and PVC roofing",
        index: 0,
        route: "tpo-and-pvc-roofing",
      },
      {
        name: "Metal roofing",
        index: 1,
        route: "metal-roofing",
      },
      {
        name: "Modified bitumen roofing",
        index: 2,
        route: "modified-bitumen-roofing",
      },
      {
        name: "Shingle roofing",
        index: 3,
        route: "shingle-roofing",
        disabled: true,
        // waiting on content and assets for this
      },
      {
        name: "Wall panels",
        index: 4,
        route: "wall-panels",
        disabled: true,
        // waiting on content and assets for this
      },
      {
        name: "Roof coatings",
        index: 5,
        route: "roof-coatings",
        // waiting on content and assets for this
      },
    ],
  },
  {
    name: "Repair vs replacement",
    index: 4,
    route: "repair-vs-replacement",
  },
  {
    name: "Construction services",
    index: 5,
    route: "construction-services",
    isParent: true,
    children: [
      {
        name: "Drywall",
        index: 0,
        route: "drywall",
      },
      {
        name: "Fencing",
        index: 1,
        route: "fencing",
      },
      {
        name: "Siding",
        index: 2,
        route: "siding",
      },
      {
        name: "Demolition",
        index: 3,
        route: "demolition",
      },
      {
        name: "Renovation",
        index: 4,
        route: "renovation",
        disabled: true,
      },
    ],
  },
  {
    name: "Financing",
    index: 6,
    route: "financing",
  },
  {
    name: "Contact",
    index: 7,
    route: "contact",
  },
];
