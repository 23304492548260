import {
  faBuilding,
  faLightbulb,
  faSun,
} from "@fortawesome/free-regular-svg-icons";
import CommonDescription from "../../../shared/components/Common/CommonDescription";
import CommonRouteImageWithTextHeader from "../../../shared/components/Common/CommonRouteImageWithTextHeader";
import CommonRouteContainer from "../../../shared/components/container/CommonRouteContainer";
import SummaryIconContainer from "../../../shared/components/Summary/SummaryIconContainer";
import SpringBox from "../../../shared/components/SpringBox";

export default function TpoAndPvcRoofing() {
  return (
    <CommonRouteContainer
      titlePrefix="TPO and PVC roofing"
      metaDescription="TPO (Thermoplastic Polyolefin) and PVC (Polyvinyl Chloride) roofing
        systems are renowned for their durability, energy efficiency, and
        versatility"
    >
      <CommonRouteImageWithTextHeader
        image={require("./TpoPvcRoofing.jpg")}
        alt={"TPO and PVC roofing"}
        header={"TPO and PVC roofing"}
        subHeader={"Superior resistance"}
        aria-label="Thermoplastic polyolefin and Polyvinyl chloride image header"
      />
      <SummaryIconContainer
        aria-label="Summary icons"
        icons={[
          {
            icon: faSun,
            text: "UV and weather protection",
          },
          {
            icon: faLightbulb,
            text: "Energy-saving roofs",
          },
          {
            icon: faBuilding,
            text: "Residential and commericial",
          },
        ]}
      />
      <SpringBox>
        <CommonDescription aria-label="What we offer and how they help protect your assets">
          TPO (Thermoplastic Polyolefin) and PVC (Polyvinyl Chloride) roofing
          systems are renowned for their durability, energy efficiency, and
          versatility. At Noble Assurance Group, we specialize in installing and
          maintaining top-quality TPO and PVC roofing solutions that provide
          excellent weather resistance and long-term performance. These
          materials are ideal for both commercial and residential roofs,
          offering superior durability against UV rays, chemicals, and
          punctures. With a range of colors and styles available, our team of
          experts ensures your roof not only performs flawlessly but also
          enhances the aesthetic appeal of your property. Trust Noble Assurance
          Group for reliable TPO and PVC roofing solutions that stand the test
          of time.
        </CommonDescription>
      </SpringBox>
    </CommonRouteContainer>
  );
}
