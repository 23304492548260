import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import {
  faCheck,
  faHouseCircleXmark,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import GradingIcon from "@mui/icons-material/Grading";
import { Box, Typography } from "@mui/material";
import { footerBackground } from "../../assets/Colors";
import {
  groupedSectionGap,
  smallGroupedSectionGap,
} from "../../shared/Constants/PaddingConstants";
import CommonDescription from "../../shared/components/Common/CommonDescription";
import CommonHeader from "../../shared/components/Common/CommonHeader";
import CommonRouteImageWithTextHeader from "../../shared/components/Common/CommonRouteImageWithTextHeader";
import CommonSubHeader from "../../shared/components/Common/CommonSubHeader";
import SpringBox from "../../shared/components/SpringBox";
import CommonRouteContainer from "../../shared/components/container/CommonRouteContainer";
import { useMinWidth } from "../../shared/hooks/useMinWidth";
import useScalableSizes from "../../shared/hooks/useScalableSizes";
import FinancePoint from "./FinancePoint";
import PaymentCalculator from "./PaymentCalculator/PaymentCalculator";
import TermBox from "./TermBox";

export default function Financing() {
  const { subHeaderTextSize, disclaimerTextSize } = useScalableSizes();

  return (
    <CommonRouteContainer
      titlePrefix="Financing"
      metaDescription="Financing your general construction or home remodel with Noble Assurance
        Group's preferred lender partners offers a seamless and affordable
        solution to restoring and protecting your home"
    >
      <CommonRouteImageWithTextHeader
        alt="Financing"
        image={require("./Financing.jpg")}
        header={"Financing"}
        subHeader={"We've got you covered"}
        aria-label="Financing header and image"
      />
      <CommonDescription aria-label="How we can help with communication with lenders">
        Financing your general construction or home remodel with Noble Assurance
        Group's preferred lender partners offers a seamless and affordable
        solution to restoring and protecting your home. Our trusted partners
        provide flexible financing options with low- cost payments, ensuring
        that your home improvement projects are accessible and manageable.
        Whether you're looking to renovate your kitchen, remodel your bathroom,
        or undertake a complete home restoration, our lenders work with you to
        tailor a financing plan that fits your budget. With competitive interest
        rates and personalized loan options, you can confidently invest in your
        home, knowing that it will be beautifully restored and safely protected
        for years to come. Let Noble Assurance Group and our preferred lenders
        help you turn your vision into reality with ease and peace of mind.
      </CommonDescription>
      <SpringBox spring={false} aria-label="Term options container">
        <Box>
          <CommonHeader>Consumer credit center</CommonHeader>
          <CommonSubHeader>
            Let our experts help you with your loan needs
          </CommonSubHeader>
        </Box>
        <Box height={smallGroupedSectionGap} />
        <Typography
          sx={{
            fontFamily: "Kanit",
            fontSize: subHeaderTextSize,
            color: footerBackground,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Term options
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: useMinWidth(800) ? "row" : "column",
            alignItems: "center",
            justifyContent: "center",
            gap: useMinWidth(800) ? "5px" : "10px",
          }}
        >
          <TermBox text={"Long term"} aria-label="Long term options">
            <ul>
              <li>Low monthly payments</li>
              <li>Fixed interest</li>
              <li>2 - 20 years</li>
              <li>No pre-payment penalties</li>
            </ul>
          </TermBox>
          <Box
            sx={{
              height: "100%",
              color: "#878787",
              width: "5px",
            }}
          />
          <TermBox text={"Short term"} aria-label="Short term options">
            <ul>
              <li>Same as cash payment</li>
              <li>No interest payments</li>
              <li>12 months deferred</li>
              <li>Minimum monthly payments</li>
            </ul>
          </TermBox>
        </Box>
        <Box height={groupedSectionGap} />
        <Typography
          sx={{
            fontFamily: "Kanit",
            fontSize: subHeaderTextSize,
            color: "#454545",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Noble Assurance Group home inprovement lending
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
          aria-label="Points to know"
        >
          <FinancePoint
            icon={<AttachMoneyIcon />}
            text={"Loan amounts up to $150,000"}
          />
          <FinancePoint
            icon={<FontAwesomeIcon icon={faHandshake} size="lg" />}
            text={"Unsecured"}
          />
          <FinancePoint
            icon={<FontAwesomeIcon icon={faHouseCircleXmark} size="lg" />}
            text={"No equity required"}
          />
          <FinancePoint icon={<GradingIcon />} text={"Soft credit check"} />
          <FinancePoint
            icon={<FontAwesomeIcon icon={faCheck} size="lg" />}
            text={"Point of need approvals"}
          />
          <FinancePoint
            icon={<FontAwesomeIcon icon={faLock} size="lg" />}
            text={"Fast, secure, paperless process"}
          />
        </Box>
      </SpringBox>
      <SpringBox>
        <Box>
          <CommonHeader>Payment calculator</CommonHeader>
          <CommonSubHeader>Get a monthly payment estimate</CommonSubHeader>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PaymentCalculator aria-label="Monthly payment calculator" />
        </Box>
        <CommonDescription
          aria-label="Monthly payment calculator disclaimer"
          fontSize={disclaimerTextSize}
          color="#545454"
        >
          Please note that this monthly payment calculation is an estimate. Your
          bank will determine the exact loan payment amount after loan approval.
          The accurancy of these calculations is not guaranteed nor is its
          applicability to your individual circumstances. For some loan types,
          the monthly payment may change depending upon when funds are disbursed
          to your contractor, among other factors.
        </CommonDescription>
      </SpringBox>
    </CommonRouteContainer>
  );
}
