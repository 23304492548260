import { Box, Button, Typography, lighten } from "@mui/material";
import { useRef, useState } from "react";
import {
  footerBackground,
  nobleOrange,
  offWhite,
} from "../../../assets/Colors";
import { useAppDispatch, useAppSelector } from "../../../redux/ReduxHooks";
import {
  setEstimatedMonthlyPayment,
  setInterestRate,
  setLoanAmount,
  setLoanTerm,
} from "../../../redux/paymentCalculatorSlice";
import { smallGroupedSectionGap } from "../../../shared/Constants/PaddingConstants";
import { useGroupedSectionPadding } from "../../../shared/hooks/useGroupedSectionPadding";
import { CommonReduxField } from "../../../shared/components/container/CommonReduxField";
import { useMinWidth } from "../../../shared/hooks/useMinWidth";

export default function PaymentCalculator() {
  // Constants
  const fadeInDuration = 450;

  // Refs
  const fieldRefs = [useRef<any>(null), useRef<any>(null), useRef<any>(null)];

  // State
  const [loanAmountOpacity, setLoanAmountOpacity] = useState<number>(1);

  // Redux
  const loanAmount = useAppSelector(
    (state) => state.paymentCalculator.loanAmount
  );
  const interestRate = useAppSelector(
    (state) => state.paymentCalculator.interestRate
  );
  const loanTerm = useAppSelector((state) => state.paymentCalculator.loanTerm);
  const estimatedMontlyPayment = useAppSelector(
    (state) => state.paymentCalculator.estimatedMontlyPayment
  );

  const dispatch = useAppDispatch();

  function getLoanAmountHelperText(value: string) {
    const parsed = parseFloat(value);

    if (!value || !value.trim()) return "Loan amount is required";
    else if (isNaN(parsed)) return "Loan amount must be a valid number";
    else if (parsed <= 0) return "Loan amount must be greater than 0";
    else return "";
  }

  function getInterestRateHelperText(value: string) {
    const parsed = parseFloat(value);

    if (!value || !value.trim()) return "Interest rate is required";
    else if (isNaN(parsed)) {
      return "Interest rate must be a valid percent";
    } else if (parsed > 100.0 || parsed < 0.0) {
      return "Interest rate must be between 0 and 100";
    } else return "";
  }

  function getLoanTermHelperText(value: string) {
    const parsed = parseFloat(value);

    if (!value || !value.trim()) return "Loan term is required";
    else if (isNaN(parsed)) {
      return "Loan term must be a valid number";
    } else if (parsed <= 0) {
      return "Loan term must be greater than 0";
    } else return "";
  }

  function onCalculateClicked() {
    const parsedLoanAmount = parseFloat(loanAmount.replaceAll(",", ""));
    const parsedInterestRate = parseFloat(interestRate);
    const parsedLoanTerm = parseFloat(loanTerm);

    fieldRefs.forEach((fieldRef: any) => {
      if (fieldRef.current) {
        fieldRef.current.triggerHelperTextEvaluation();
      }
    });

    let validationsFailed = false;

    if (getLoanAmountHelperText(loanAmount)) validationsFailed = true;
    if (getInterestRateHelperText(interestRate)) validationsFailed = true;
    if (getLoanTermHelperText(loanTerm)) validationsFailed = true;

    if (validationsFailed) {
      dispatch(setEstimatedMonthlyPayment(""));
      return;
    }

    const annualInterestRate = parsedInterestRate / 100.0;
    const monthlyInterestRate = annualInterestRate / 12.0;
    const numberOfPayments = parsedLoanTerm * 12.0;

    const monthlyPayment =
      (parsedLoanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));
    dispatch(setEstimatedMonthlyPayment(monthlyPayment.toFixed(2)));

    setLoanAmountOpacity(0);
    setTimeout(() => {
      setLoanAmountOpacity(1);
    }, 350);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: smallGroupedSectionGap,
        width: "100%",
        paddingY: useGroupedSectionPadding(),
        minWidth: useMinWidth(500) ? "350px" : "auto",
      }}
    >
      <CommonReduxField
        ref={fieldRefs[0]}
        label={"Loan amount ($)"}
        value={loanAmount}
        setter={setLoanAmount}
        getHelperText={getLoanAmountHelperText}
      />
      <CommonReduxField
        ref={fieldRefs[1]}
        label={"Interest rate (%)"}
        value={interestRate}
        setter={setInterestRate}
        getHelperText={getInterestRateHelperText}
      />
      <CommonReduxField
        ref={fieldRefs[2]}
        label={"Loan term (years)"}
        value={loanTerm}
        setter={setLoanTerm}
        getHelperText={getLoanTermHelperText}
      />
      <Button
        fullWidth
        variant="contained"
        onClick={onCalculateClicked}
        sx={{
          textTransform: "none",
          fontFamily: "Raleway",
          fontSize: "16px",
          backgroundColor: footerBackground,
          color: offWhite,
          fontWeight: "bold",
          transition: "all 0.15s linear",
          "&:hover": {
            backgroundColor: lighten(nobleOrange, 0.2),
            color: footerBackground,
          },
        }}
      >
        Calculate
      </Button>
      {estimatedMontlyPayment && (
        <Typography
          sx={{
            opacity: loanAmountOpacity,
            transition: `opacity ${fadeInDuration}ms ease-in-out`,
          }}
        >
          Estimated monthly payment: ${estimatedMontlyPayment}
        </Typography>
      )}
    </Box>
  );
}
