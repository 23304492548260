import { Box } from "@mui/material";
import { useState } from "react";
import { nobleRed } from "../../assets/Colors";
import { groupedSectionGap } from "../Constants/PaddingConstants";
import { useGroupedSectionPadding } from "../hooks/useGroupedSectionPadding";

interface SpringBoxProps {
  children?: React.ReactNode;
  spring?: boolean;
  flexDirection?: "row" | "column";
}

export default function SpringBox({
  children,
  spring = false,
  flexDirection = "column",
}: SpringBoxProps) {
  // Constants
  const borderRadius = 16;
  const borderLen = 6;
  const borderOffset = 3;

  // State
  const [hovered, setHovered] = useState<boolean>(false);

  const springStyling = {
    "::before": {
      content: '""',
      position: "absolute",
      top: `-${borderOffset}px`,
      left: `-${borderOffset}px`,
      width: "20%",
      height: `${borderLen}px`,
      backgroundColor: nobleRed,
    },
    "::after": {
      content: '""',
      position: "absolute",
      top: `-${borderOffset}px`,
      left: `-${borderOffset}px`,
      width: `${borderLen}px`,
      height: "20%",
      backgroundColor: nobleRed,
    },
    "&:hover::after": {
      animation: "springAnimationDown 2.5s ease-out forwards",
    },
    "&:not(:hover)::after": {
      animation: "springAnimationUp 2.5s ease-out forwards",
    },
    "@keyframes springAnimationDown": {
      "0%": { height: "20%" },
      "10%": { height: "60%" },
      "20%": { height: "40%" },
      "30%": { height: "55%" },
      "40%": { height: "45%" },
      "50%": { height: "52%" },
      "60%": { height: "48%" },
      "70%": { height: "51%" },
      "80%": { height: "49%" },
      "90%": { height: "50.5%" },
      "100%": { height: "50%" },
    },
    "@keyframes springAnimationUp": {
      "0%": { height: "50%" },
      "15%": { height: "15%" },
      "30%": { height: "28%" },
      "45%": { height: "17%" },
      "60%": { height: "23%" },
      "72%": { height: "19%" },
      "82%": { height: "21%" },
      "90%": { height: "19.5%" },
      "95%": { height: "20.2%" },
      "100%": { height: "20%" },
    },
  };

  const boxStyle = (isHorizontal: boolean) => ({
    position: "absolute" as const,
    width: isHorizontal ? (hovered ? "40%" : "20%") : `${borderLen}px`,
    height: isHorizontal ? `${borderLen}px` : hovered ? "40%" : "20%",
    backgroundColor: nobleRed,
    transition: isHorizontal
      ? "width 0.5s ease-in-out"
      : "height 0.5s ease-in-out",
  });

  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        maxWidth: "1200px",
        position: "relative",
        borderRadius: spring
          ? `0 ${borderRadius}px ${borderRadius}px ${borderRadius}px`
          : `0 ${borderRadius}px 0px ${borderRadius}px`,
        ...(spring ? springStyling : {}),
      }}
    >
      {!spring && (
        <>
          <Box
            sx={{
              ...boxStyle(true),
              top: `-${borderOffset}px`,
              left: `-${borderOffset}px`,
            }}
          />
          <Box
            sx={{
              ...boxStyle(false),
              top: `-${borderOffset}px`,
              left: `-${borderOffset}px`,
            }}
          />
          <Box
            sx={{
              ...boxStyle(false),
              bottom: `-${borderOffset}px`,
              right: `-${borderOffset}px`,
            }}
          />
          <Box
            sx={{
              ...boxStyle(true),
              bottom: `-${borderOffset}px`,
              right: `-${borderOffset}px`,
            }}
          />
        </>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: flexDirection,
          gap: groupedSectionGap,
          backgroundColor: "#F7F7F7",
          boxShadow: "4px 6px 4px rgba(0, 0, 0, 0.15)",
          borderRadius: spring
            ? `0 ${borderRadius}px ${borderRadius}px ${borderRadius}px`
            : `0 ${borderRadius}px 0 ${borderRadius}px`,
          padding: useGroupedSectionPadding(),
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
