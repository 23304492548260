interface AccreditationLogoProps {
  alt: string;
  src: any;
}

export default function AccreditationLogo({
  alt,
  src,
}: AccreditationLogoProps) {
  return (
    <img
      alt={alt}
      style={{
        maxWidth: "90%",
        width: "150px",
      }}
      src={src}
    />
  );
}
