import CloseIcon from "@mui/icons-material/Close";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useAppDispatch } from "../../../redux/ReduxHooks";

interface CommonReduxFieldProps {
  label: string;
  value: string;
  setter: any;
  getHelperText: (value: string) => string;
  multiline?: boolean;
  onEnter?: () => void;
}

export const CommonReduxField = forwardRef(
  (
    {
      label,
      value,
      setter,
      getHelperText,
      multiline = false,
      onEnter,
    }: CommonReduxFieldProps,
    ref
  ) => {
    // Redux
    const dispatch = useAppDispatch();

    // State
    const [helperText, setHelperText] = useState<string>("");
    const [touched, setTouched] = useState<boolean>(false);

    function updateHelperText() {
      if (!touched) return;
      setHelperText(getHelperText(value));
    }

    // Effects
    useEffect(() => {
      updateHelperText();
    }, [value]);

    // Expose method to trigger helper text evaluation
    useImperativeHandle(ref, () => ({
      triggerHelperTextEvaluation() {
        setHelperText(getHelperText(value));
      },
      touch() {
        setTouched(true);
      },
      untouch() {
        setTouched(false);
      },
    }));

    const endClearIcon = (
      <InputAdornment position="end">
        {value.length > 0 && (
          <IconButton
            onClick={() => {
              dispatch(setter(""));
              setHelperText("");
              setTouched(false);
            }}
            edge="end"
          >
            <CloseIcon />
          </IconButton>
        )}
      </InputAdornment>
    );

    return (
      <TextField
        multiline={multiline}
        rows={multiline ? 6 : 1}
        size="small"
        label={label}
        fullWidth
        value={value}
        onChange={(e: any) => {
          const typedValue = e.target.value;
          dispatch(setter(typedValue));
          setTouched(true);
        }}
        onKeyDown={(e: any) => {
          if (e.key === "Enter") onEnter?.();
        }}
        error={touched && !!helperText}
        helperText={touched && helperText}
        InputProps={{
          endAdornment: endClearIcon,
        }}
      />
    );
  }
);
