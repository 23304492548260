import {
  faHouseChimneyCrack,
  faScrewdriverWrench,
  faShield,
} from "@fortawesome/free-solid-svg-icons";
import CommonDescription from "../../../shared/components/Common/CommonDescription";
import CommonRouteImageWithTextHeader from "../../../shared/components/Common/CommonRouteImageWithTextHeader";
import CommonSubHeader from "../../../shared/components/Common/CommonSubHeader";
import CommonRouteContainer from "../../../shared/components/container/CommonRouteContainer";
import SpringBox from "../../../shared/components/SpringBox";
import SummaryIconContainer from "../../../shared/components/Summary/SummaryIconContainer";

export default function HistoricalRoofing() {
  return (
    <CommonRouteContainer
      titlePrefix="Historical roofing"
      metaDescription=" Historical roofing offers a blend of timeless beauty and enduring
        craftsmanship, perfect for preserving the character and charm of
        historic homes and buildings"
    >
      <CommonRouteImageWithTextHeader
        image={require("./HistoricalRoofing.jpg")}
        alt={"Historical roofing"}
        header={"Historical roofing"}
        subHeader={"Maintaining authenticity without protection compromise"}
        aria-label="Historical roofing image and header"
      />
      <SummaryIconContainer
      aria-label="Summary icons"
        icons={[
          {
            icon: faHouseChimneyCrack,
            text: "Authentic restoration",
          },
          {
            icon: faScrewdriverWrench,
            text: "Slate, tile, cedar",
          },
          {
            icon: faShield,
            text: "Lasting protection",
          },
        ]}
      />
      <CommonDescription aria-label="What we offer when it comes to historical roofing in terms of preservation">
        Historical roofing offers a blend of timeless beauty and enduring
        craftsmanship, perfect for preserving the character and charm of
        historic homes and buildings. At Noble Assurance Group, we specialize in
        installing and maintaining historical roofing systems that honor the
        architectural integrity of your property while providing modern
        functionality. Whether you're restoring a Victorian-era home or
        renovating a historic landmark, our team is equipped with the expertise
        to ensure your roofing solution meets the highest standards of
        authenticity and durability. With a variety of materials and techniques
        available, including slate, tile, and cedar shakes, we can enhance the
        aesthetic appeal and historical significance of your structure while
        ensuring it remains protected against the elements for years to come.
      </CommonDescription>
      <SpringBox>
        <CommonSubHeader>
          Contact us today for a free inspection
        </CommonSubHeader>
        <CommonDescription aria-label="Contact us to learn about your free inspection">
          Curious about the condition of your historical roof? Noble Assurance
          Group offers free, no-obligation inspections to assess its state and
          recommend the best course of action. Our knowledgeable professionals
          will thoroughly examine your roof for any signs of wear or damage,
          providing you with a comprehensive report and expert advice. Don't
          wait until minor issues become major concerns—contact us today to
          schedule your free historical roof inspection and ensure your property
          continues to stand as a testament to its rich heritage.
        </CommonDescription>
      </SpringBox>
    </CommonRouteContainer>
  );
}
