import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import { nobleOrange } from "../../assets/Colors";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useState } from "react";
import { Link } from "react-router-dom";

interface ContactRowProps {
  icon: IconProp;
  text: string;
  link?: string;
}

export default function ContactRow({ icon, text, link }: ContactRowProps) {
  const [mouseIn, setMouseIn] = useState<boolean>(false);

  const content = (
    <Box
      onMouseEnter={() => setMouseIn(true)}
      onMouseLeave={() => setMouseIn(false)}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        color: mouseIn && link ? nobleOrange : "white",
        transition: "color 0.15s linear",
        cursor: link ? "pointer" : "auto",
      }}
    >
      <FontAwesomeIcon icon={icon} size="lg" color={nobleOrange} />
      <Typography
        sx={{
          fontFamily: "Kanit",
          color: "inherit",
          fontSize: "16px",
          fontWeight: "bold",
          wordWrap: "break-word",
        }}
      >
        {text}
      </Typography>
    </Box>
  );

  if (!link) return content;

  return (
    <Link target="_blank" to={link}>
      {content}
    </Link>
  );
}
