import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ContactFormState {
  emailAddress: string;
  phoneNumber: string;
  fullName: string;
  preferredName: string;
  message: string;
}

const initialState: ContactFormState = {
  emailAddress: "",
  phoneNumber: "",
  fullName: "",
  preferredName: "",
  message: "",
};

export const contactFormSlice = createSlice({
  name: "contactForm",
  initialState,
  reducers: {
    setEmailAddress: (state, action: PayloadAction<string>) => {
      state.emailAddress = action.payload;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setFullName: (state, action: PayloadAction<string>) => {
      state.fullName = action.payload;
    },
    setPreferredName: (state, action: PayloadAction<string>) => {
      state.preferredName = action.payload;
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    resetContactFormSlice: () => initialState,
  },
});

export const {
  setEmailAddress,
  setPhoneNumber,
  setFullName,
  setPreferredName,
  setMessage,
  resetContactFormSlice,
} = contactFormSlice.actions;
export default contactFormSlice.reducer;
