import { Box, Typography } from "@mui/material";
import { nobleOrange, offWhite } from "../../assets/Colors";
import { useMinWidth } from "../../shared/hooks/useMinWidth";

interface FinancePointProps {
  icon: any;
  text: string;
}

export default function FinancePoint({ icon, text }: FinancePointProps) {
  const largerPoints = useMinWidth(500);

  return (
    <Box
      sx={{
        width: largerPoints ? "350px" : "280px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        padding: "10px",
        backgroundColor: "#303030",
        boxShadow: "4px 6px 4px rgba(0, 0, 0, 0.15)",
        borderRadius: "200px",
        paddingX: "20px",
        gap: "20px",
        color: offWhite,
        transition: "color 0.15s linear",
        "&:hover": {
          color: nobleOrange,
        },
      }}
    >
      {icon}
      <Typography
        sx={{
          fontFamily: "Raleway",
          fontSize: largerPoints ? "16px" : "14px",
          color: "inherit",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}
