import {
  faHouseCircleCheck,
  faThermometerHalf,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/material";
import CommonDescription from "../../../shared/components/Common/CommonDescription";
import CommonRouteImageWithTextHeader from "../../../shared/components/Common/CommonRouteImageWithTextHeader";
import CommonSubHeader from "../../../shared/components/Common/CommonSubHeader";
import CommonRouteContainer from "../../../shared/components/container/CommonRouteContainer";
import SpringBox from "../../../shared/components/SpringBox";
import SummaryIconContainer from "../../../shared/components/Summary/SummaryIconContainer";
import {
  groupedSectionPadding,
  smallGroupedSectionGap,
} from "../../../shared/Constants/PaddingConstants";
import BeSureToIncludeRow from "../../contact/BeSureToIncludeRow";

export default function Siding() {
  return (
    <CommonRouteContainer
      titlePrefix="Siding"
      metaDescription=" At Noble Assurance Group, we specialize in providing high-quality siding
        services that transform the look and functionality of your property's
        exterior"
    >
      <CommonRouteImageWithTextHeader
        image={require("./Siding.jpg")}
        alt={"Siding"}
        header={"Siding"}
        subHeader={"Excellent personalized solutions"}
        aria-label="Siding header and image"
      />
      <SummaryIconContainer
        aria-label="Summary icons"
        icons={[
          {
            icon: faHouseCircleCheck,
            text: "Transformed exterior",
          },
          {
            icon: faToolbox,
            text: "Flawless craftsmanship",
          },
          {
            icon: faThermometerHalf,
            text: "Insulated siding options",
          },
        ]}
      />
      <CommonDescription aria-label="What we specialize in">
        At Noble Assurance Group, we specialize in providing high-quality siding
        services that transform the look and functionality of your property's
        exterior. Whether you're looking to upgrade your home's curb appeal,
        improve energy efficiency, or protect your property from the elements,
        our comprehensive siding solutions are designed to meet your needs.
      </CommonDescription>
      <Box>
        <CommonSubHeader>Expert siding installation</CommonSubHeader>
        <CommonDescription aria-label="How we cater to different properties">
          Our expert siding installation services cater to both residential and
          commercial properties. We offer a variety of siding materials,
          including vinyl, fiber cement, wood, and metal, allowing you to choose
          the perfect style and color to complement your property's
          architecture. Our skilled team ensures precise installation,
          delivering a seamless and attractive finish that enhances the overall
          appearance of your building.
        </CommonDescription>
      </Box>
      <Box>
        <CommonSubHeader>Siding repair and maintenance</CommonSubHeader>
        <CommonDescription aria-label="How we can help the appeal and aesthetic of your property">
          Damaged or worn siding can detract from your property's aesthetics and
          compromise its protection against the elements. Our siding repair
          services address issues such as cracks, warping, and weather damage,
          restoring your siding to its original condition. We also provide
          routine maintenance services to keep your siding looking great and
          performing well, extending its lifespan and maintaining its value.
        </CommonDescription>
      </Box>
      <Box>
        <CommonSubHeader>Energy efficient siding options</CommonSubHeader>
        <CommonDescription aria-label="How we can save you energy and thus money with our siding options">
          Upgrade to energy-efficient siding to reduce your energy bills and
          increase your property's comfort. Our selection of insulated siding
          options provides better thermal performance, helping to regulate
          indoor temperatures and reduce heat loss. This not only enhances your
          home's
        </CommonDescription>
      </Box>
      <Box>
        <CommonSubHeader>Custom design and aesthetic appeal</CommonSubHeader>
        <CommonDescription aria-label="The custom and aesthetic options we provide and the appeal they bring to your asset">
          Choose from a wide range of siding styles and finishes to achieve the
          look you desire. Whether you prefer the classic charm of wood, the
          durability of fiber cement, or the low- maintenance benefits of vinyl,
          we offer solutions that enhance your property's curb appeal. Our
          design experts work with you to select the best options that match
          your taste and budget, creating a customized exterior that stands out.
        </CommonDescription>
      </Box>
      <Box>
        <CommonSubHeader>
          Professional consultation and guidance
        </CommonSubHeader>
        <CommonDescription aria-label="How we can help">
          Our siding services include a thorough consultation process, where we
          assess your property's needs and discuss your preferences. We provide
          expert guidance on the best materials and styles to suit your goals,
          ensuring that the final result not only looks great but also offers
          lasting protection and value.
        </CommonDescription>
      </Box>
      <SpringBox>
        <CommonSubHeader>
          Why choose Noble Assurance Group for your siding needs?
        </CommonSubHeader>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingX: groupedSectionPadding,
            alignItems: "start",
            gap: "5px",
          }}
          aria-label="Why to choose us for your siding needs"
        >
          <BeSureToIncludeRow text={"High-quality materials"} number={1} />
          <CommonDescription color="#565656" fontSize="14px">
            We use premium materials that offer durability, performance, and
            aesthetic appeal.
          </CommonDescription>
          <Box height={smallGroupedSectionGap} />
          <BeSureToIncludeRow text={"Experienced craftsmanship"} number={2} />
          <CommonDescription color="#565656" fontSize="14px">
            Our team brings years of experience and attention to detail to every
            project.
          </CommonDescription>
          <Box height={smallGroupedSectionGap} />
          <BeSureToIncludeRow text={"Personalized solutions"} number={3} />
          <CommonDescription color="#565656" fontSize="14px">
            We tailor our services to meet your specific needs, providing custom
            siding solutions.
          </CommonDescription>
          <Box height={smallGroupedSectionGap} />
          <BeSureToIncludeRow text={"Commitment to excellence"} number={4} />
          <CommonDescription color="#565656" fontSize="14px">
            We are dedicated to delivering outstanding results and ensuring your
            satisfaction.
          </CommonDescription>
        </Box>
      </SpringBox>
    </CommonRouteContainer>
  );
}
