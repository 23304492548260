import { Box } from "@mui/material";
import { groupedSectionGap } from "../../Constants/PaddingConstants";
import SummaryIcon, { SummaryIconProps } from "./SummaryIcon";

interface Props {
  icons: Partial<SummaryIconProps>[];
}

export default function SummaryIconContainer({ icons }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: groupedSectionGap,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {icons.map((icon, index) => (
        // @ts-expect-error valid props are provided
        <SummaryIcon {...icon} number={index + 1} />
      ))}
    </Box>
  );
}
