import { Typography } from "@mui/material";
import { offBlack } from "../../../assets/Colors";
import useScalableSizes from "../../hooks/useScalableSizes";

interface CommonDescriptionProps {
  children?: React.ReactNode;
  color?: string;
  fontSize?: string;
}

export default function CommonDescription({
  children,
  color = offBlack,
  fontSize,
}: CommonDescriptionProps) {
  const { descriptionTextSize } = useScalableSizes();

  return (
    <Typography
      component="div"
      sx={{
        fontFamily: "Roboto",
        fontSize: fontSize ?? descriptionTextSize,
        color: color,
        textAlign: "left",
        maxWidth: "1200px",
      }}
    >
      {children}
    </Typography>
  );
}
