import { Typography } from "@mui/material";
import { nobleRed } from "../../../assets/Colors";
import useScalableSizes from "../../hooks/useScalableSizes";

interface CommonHeaderProps {
  children: React.ReactNode | string;
  color?: string;
}

export default function CommonHeader({
  children,
  color = nobleRed,
}: CommonHeaderProps) {
  const { headerTextSize } = useScalableSizes();

  return (
    <Typography
      sx={{
        fontFamily: "Kanit",
        fontSize: headerTextSize,
        color: color,
        textAlign: "center",
        fontWeight: "bold",
      }}
    >
      {children}
    </Typography>
  );
}
