import React, { createContext, useContext, useState } from "react";

interface FooterHeightContextType {
  footerHeight: number;
  setFooterHeight: (height: number) => void;
}

const FooterHeightContext = createContext<FooterHeightContextType | undefined>(
  undefined
);

export const FooterHeightProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [footerHeight, setFooterHeight] = useState<number>(0);

  return (
    <FooterHeightContext.Provider value={{ footerHeight, setFooterHeight }}>
      {children}
    </FooterHeightContext.Provider>
  );
};

export const useFooterHeight = () => {
  const context = useContext(FooterHeightContext);
  if (context === undefined) {
    throw new Error(
      "useFooterHeight must be used within a FooterHeightProvider"
    );
  }
  return context;
};
