import { Box, Typography } from "@mui/material";
import { footerBackground } from "../../assets/Colors";
import useScalableSizes from "../../shared/hooks/useScalableSizes";

interface Props {
  number: number;
  label: string;
  color: string;
}

export default function NumberIncrement({ number, label, color }: Props) {
  const { subSubHeaderTextSize, isLargeScreen } = useScalableSizes();
  const numberSize = isLargeScreen ? "56px" : "48px";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: footerBackground,
        padding: "20px",
        borderRadius: "16px",
        width: "250px",
        height: "150px",
      }}
    >
      <Typography
        sx={{
          color: color,
          fontWeight: "bold",
          fontFamily: "Kanit",
          fontSize: numberSize,
        }}
      >
        {number}+
      </Typography>
      <Typography
        sx={{
          color: "#DFDFDF",
          fontFamily: "Raleway",
          fontSize: subSubHeaderTextSize,
          textAlign: "center",
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}
