import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faClock, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowRight,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography, lighten } from "@mui/material";
import { useEffect, useMemo, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { footerBackground, nobleOrange } from "../../assets/Colors";
import {
  footerPadding,
  groupedSectionGap,
  largeGroupedSectionGap,
} from "../../shared/Constants/PaddingConstants";
import { useFooterHeight } from "../../shared/context/FooterHeightContext";
import { useMinWidth } from "../../shared/hooks/useMinWidth";
import {
  contactEmail,
  contactFacebook,
  contactInstagram,
  contactNumber,
  contactNumberFormatted,
} from "../contact/ContactConstants";
import ContactRow from "./ContactRow";

/**
 * The footer component for all {@link CommonRouteContainer}s to place at the end of the page.
 * If the content does not take up the entire viewport height, the footer will still be placed
 * at the end of the viewport.
 */
export default function Footer() {
  // Constants
  const address = "2594 Front St, Slidell, LA 70458";

  // Hooks
  const canShowContactAsRow = useMinWidth(1000);
  const canShowNonColumnFooter = useMinWidth(750);
  const location = useLocation();

  // Context
  const { setFooterHeight } = useFooterHeight();

  // Refs
  const footerRef = useRef<HTMLDivElement>(null);

  // Effects
  useEffect(() => {
    const updateFooterHeight = () => {
      if (footerRef.current) {
        setFooterHeight(footerRef.current.offsetHeight);
      }
    };

    updateFooterHeight();
    window.addEventListener("resize", updateFooterHeight);

    return () => {
      window.removeEventListener("resize", updateFooterHeight);
    };
  }, [setFooterHeight]);

  // Memos
  const onContactPage = useMemo(
    () => location.pathname.includes("contact"),
    [location]
  );

  function getGoogleMapsLink(address: string) {
    return "https://www.google.com/maps/place/" + address.replaceAll(" ", "+");
  }

  const businessHoursAndAddress = (
    <Box
      sx={{
        display: "flex",
        flexDirection: canShowContactAsRow ? "row" : "column",
        gap: canShowContactAsRow ? "20px" : "10px",
      }}
    >
      <ContactRow icon={faClock} text="8am - 6pm, Monday - Saturday" />
      <ContactRow
        icon={faLocationDot}
        text={address}
        link={getGoogleMapsLink(address)}
      />
    </Box>
  );

  const contactButton = (
    <Link to="/contact">
      <Button
        aria-label="Navigate to contact page button"
        variant="contained"
        sx={{
          textTransform: "none",
          borderRadius: "200px",
          fontFamily: "Raleway",
          fontSize: "16px",
          backgroundColor: footerBackground,
          border: `2px solid ${nobleOrange}`,
          color: nobleOrange,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: lighten(nobleOrange, 0.2),
            border: `2px solid ${lighten(nobleOrange, 0.2)}`,
            color: footerBackground,
          },
          gap: "10px",
          transition: "all 0.15s linear",
        }}
      >
        Contact
        <FontAwesomeIcon icon={faArrowRight} size="lg" color="inherit" />
      </Button>
    </Link>
  );

  const primaryContactIcons = (
    <Box
      sx={{
        display: "flex",
        flexDirection: canShowContactAsRow ? "row" : "column",
        gap: canShowContactAsRow ? "10px" : "5px",
        alignItems: canShowContactAsRow ? "center" : "start",
        justifyContent: "center",
        paddingBottom: "10px",
      }}
    >
      <ContactRow
        aria-label="Call us"
        icon={faPhone}
        text={contactNumberFormatted}
        link={`tel:${contactNumber}`}
      />
      <ContactRow
        aria-label="Email us"
        icon={faEnvelope}
        text={contactEmail}
        link={`mailto:${contactEmail}`}
      />
      <ContactRow aria-label="Instagram" icon={faInstagram} text="Instagram" link={contactInstagram} />
      <ContactRow aria-label="Facebook" icon={faFacebook} text="Facebook" link={contactFacebook} />
    </Box>
  );

  function getFooterFlexDirection() {
    if (canShowContactAsRow) return "column";
    if (canShowNonColumnFooter) return "row";
    else return "column";
  }

  return (
    <Box
      ref={footerRef}
      sx={{
        display: "flex",
        flexDirection: getFooterFlexDirection(),
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        padding: footerPadding,
        backgroundColor: footerBackground,
        gap: canShowNonColumnFooter
          ? groupedSectionGap
          : largeGroupedSectionGap,
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: canShowContactAsRow ? "row" : "column",
          alignItems: "center",
          justifyContent: "center",
          gap: groupedSectionGap,
          width: "80%",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Raleway",
            color: "white",
            fontSize: "16px",
            maxWidth: "600px",
            minWidth: "50%",
            textAlign: "justify",
          }}
          aria-label="We would love to hear from you"
        >
          We're looking forward to hearing from you! Reach out to us on any of
          our platforms, call us, email us, or use the contact form to ask us a
          question.
        </Typography>
        {!onContactPage && contactButton}
        {!canShowContactAsRow && businessHoursAndAddress}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {primaryContactIcons}
        {canShowContactAsRow && businessHoursAndAddress}
      </Box>
    </Box>
  );
}
