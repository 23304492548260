import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface MailListState {
  emailAddress: string;
}

const initialState: MailListState = {
  emailAddress: "",
};

export const mailingListSlice = createSlice({
  name: "mailingList",
  initialState,
  reducers: {
    setEmailAddress: (state, action: PayloadAction<string>) => {
      state.emailAddress = action.payload;
    },
    resetMailingListSlice: () => initialState,
  },
});

export const { setEmailAddress, resetMailingListSlice } =
  mailingListSlice.actions;
export default mailingListSlice.reducer;
