import WebFont from "webfontloader";
import googleFontFamilies from "../assets/fonts.json";

export function reloadFonts() {
  WebFont.load({
    google: {
      families: googleFontFamilies,
    },
  });
}
