import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AboutUs from "../components/about-us/AboutUs";
import CommercialMetalRoofing from "../components/commercial-roofing/metal-roofing/CommercialMetalRoofing";
import ModifiedBitumenRoofing from "../components/commercial-roofing/modified-bitumen-roofing/ModifiedBitumenRoofing";
import RoofCoatings from "../components/commercial-roofing/roof-coatings/RoofCoatings";
import TpoAndPvcRoofing from "../components/commercial-roofing/tpo-and-pvc-roofing/TpoAndPvcRoofing";
import WallPanels from "../components/commercial-roofing/wall-panels/WallPanels";
import Demolition from "../components/construction-services/demolition/Demolition";
import Drywall from "../components/construction-services/drywall/Drywall";
import Fencing from "../components/construction-services/fencing/Fencing";
import Mitigation from "../components/construction-services/mitigation/Mitigation";
import Renovation from "../components/construction-services/renovation/Renovation";
import Siding from "../components/construction-services/siding/Siding";
import Contact from "../components/contact/Contact";
import Financing from "../components/financing/Financing";
import Home from "../components/home/Home";
import NotFound from "../components/not-found/NotFound";
import RepairVsReplacement from "../components/repair-vs-replacement/RepairVsReplacement";
import HistoricalRoofing from "../components/residential-roofing/historical-roofing/HistoricalRoofing";
import InsuranceClaims from "../components/residential-roofing/insurance-claims/InsuranceClaims";
import { default as MetalRoofing } from "../components/residential-roofing/metal-roofing/MetalRoofing";
import ShingleRoofing from "../components/residential-roofing/shingle-roofing/ShingleRoofing";
import { useDocumentTitle } from "../shared/context/DocumentTitleContext";
import CommercialShingleRoofing from "../components/commercial-roofing/shingle-roofing/CommercialShingleRoofing";

export default function WebsiteRoutes() {
  // Hooks
  const location = useLocation();

  // Context
  const { setDocumentTitle } = useDocumentTitle();

  // Effects
  useEffect(() => setDocumentTitle("Noble Assurance Group"));

  return (
    <Routes location={location}>
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/residential-roofing">
        <Route index element={<Navigate to="shingle-roofing" replace />} />
        <Route path="shingle-roofing" element={<ShingleRoofing />} />
        <Route path="metal-roofing" element={<MetalRoofing />} />
        <Route path="insurance-claims" element={<InsuranceClaims />} />
        <Route path="historical-roofing" element={<HistoricalRoofing />} />
      </Route>
      <Route path="/commercial-roofing">
        <Route path="tpo-and-pvc-roofing" element={<TpoAndPvcRoofing />} />
        <Route path="metal-roofing" element={<CommercialMetalRoofing />} />
        <Route
          path="modified-bitumen-roofing"
          element={<ModifiedBitumenRoofing />}
        />
        <Route path="shingle-roofing" element={<CommercialShingleRoofing />} />
        <Route path="wall-panels" element={<WallPanels />} />
        <Route path="roof-coatings" element={<RoofCoatings />} />
      </Route>
      <Route path="/repair-vs-replacement" element={<RepairVsReplacement />} />
      <Route path="/construction-services">
        <Route path="drywall" element={<Drywall />} />
        <Route path="fencing" element={<Fencing />} />
        <Route path="siding" element={<Siding />} />
        <Route path="mitigation" element={<Mitigation />} />
        <Route path="demolition" element={<Demolition />} />
        <Route path="renovation" element={<Renovation />} />
      </Route>
      <Route path="/financing" element={<Financing />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
