import {
  faHelmetSafety,
  faRecycle,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/material";
import CommonDescription from "../../../shared/components/Common/CommonDescription";
import CommonRouteImageWithTextHeader from "../../../shared/components/Common/CommonRouteImageWithTextHeader";
import CommonSubHeader from "../../../shared/components/Common/CommonSubHeader";
import CommonRouteContainer from "../../../shared/components/container/CommonRouteContainer";
import SpringBox from "../../../shared/components/SpringBox";
import SummaryIconContainer from "../../../shared/components/Summary/SummaryIconContainer";

export default function Demolition() {
  return (
    <CommonRouteContainer
      titlePrefix="Demolition"
      metaDescription="Noble Assurance Group offers professional demolition services tailored
        to meet the needs of residential, commercial, and industrial clients"
    >
      <CommonRouteImageWithTextHeader
        image={require("./Demolition.jpg")}
        alt={"Demolition"}
        header={"Demolition"}
        subHeader={"Controlled and secure"}
        aria-label="Demolition image and header"
      />
      <SummaryIconContainer
        aria-label="Summary iconsw"
        icons={[
          {
            icon: faHelmetSafety,
            text: "Prioritizing safety",
          },
          {
            icon: faRecycle,
            text: "Sustainable waste management",
          },
          {
            icon: faToolbox,
            text: "Full and selective demolition",
          },
        ]}
      />
      <CommonDescription aria-label="What we offer for demolition services">
        Noble Assurance Group offers professional demolition services tailored
        to meet the needs of residential, commercial, and industrial clients.
        Whether you're planning a renovation, new construction, or site
        preparation, our experienced team provides safe and efficient demolition
        solutions that ensure your project starts on the right foot.
      </CommonDescription>
      <Box>
        <CommonSubHeader>Comprehensive demolition solutions</CommonSubHeader>
        <CommonDescription aria-label="The wide range of needs we cover">
          Our demolition services cover a wide range of needs, including
          complete building demolitions, interior strip-outs, and selective
          demolitions. We are equipped to handle projects of all sizes and
          complexities, from small residential structures to large commercial
          buildings. Our team assesses each project individually, developing a
          customized plan that prioritizes safety, efficiency, and environmental
          responsibility.
        </CommonDescription>
      </Box>
      <Box>
        <CommonSubHeader>Safety-first approach</CommonSubHeader>
        <CommonDescription aria-label="Our safety first approach to demolition">
          At Noble Assurance Group, safety is our top priority. We adhere to
          strict safety protocols and industry regulations to ensure the
          well-being of our crew, your property, and the surrounding
          environment. Our trained professionals use state-of-the-art equipment
          and techniques to minimize risks and disruptions, providing a
          controlled and secure demolition process.
        </CommonDescription>
      </Box>
      <SpringBox>
        <CommonSubHeader>
          Environmental considerations and waste management
        </CommonSubHeader>
        <CommonDescription aria-label="The environmental considerations we take and our environmentally friendly waste management">
          We are committed to environmentally responsible practices in all our
          demolition projects. This includes proper waste management and
          recycling of materials, wherever possible. We carefully sort and
          dispose of debris, ensuring that hazardous materials are handled and
          removed safely. Our goal is to reduce the environmental impact of
          demolition activities and promote sustainability.
        </CommonDescription>
      </SpringBox>
    </CommonRouteContainer>
  );
}
