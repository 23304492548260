import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import CommonDescription from "../../../shared/components/Common/CommonDescription";
import CommonRouteImageWithTextHeader from "../../../shared/components/Common/CommonRouteImageWithTextHeader";
import CommonSubHeader from "../../../shared/components/Common/CommonSubHeader";
import CommonRouteContainer from "../../../shared/components/container/CommonRouteContainer";
import SpringBox from "../../../shared/components/SpringBox";
import SummaryIconContainer from "../../../shared/components/Summary/SummaryIconContainer";
import { faCloud, faShield } from "@fortawesome/free-solid-svg-icons";

export default function MetalRoofing() {
  return (
    <CommonRouteContainer
      titlePrefix="Metal roofing"
      metaDescription="Metal roofing systems are a durable and energy-efficient choice for
        homeowners seeking long-lasting protection and modern aesthetics"
    >
      <CommonRouteImageWithTextHeader
        image={require("./MetalRoofing.jpg")}
        alt={"Metal roofing"}
        header={"Metal roofing"}
        subHeader={"Protection with style"}
        aria-label="Metal roofing header image"
      />
      <SummaryIconContainer
        aria-label="Summary icons"
        icons={[
          {
            icon: faLightbulb,
            text: "Energy-saving roofs",
          },
          {
            icon: faShield,
            text: "Long-lasting protection",
          },
          {
            icon: faCloud,
            text: "Extreme weather performance",
          },
        ]}
      />
      <CommonDescription aria-label="Pros of metal roofing systems">
        Metal roofing systems are a durable and energy-efficient choice for
        homeowners seeking long-lasting protection and modern aesthetics. At
        Noble Assurance Group, we specialize in installing and maintaining
        top-quality metal roofs that offer superior resilience against harsh
        weather conditions. Available in a variety of styles and colors, our
        metal roofing options can enhance the look of your home while providing
        unmatched durability and energy savings. Metal roofs are known for their
        longevity, minimal maintenance requirements, and excellent performance
        in extreme weather, making them an ideal choice for Louisiana and
        Mississippi's climate.
      </CommonDescription>
      <SpringBox>
        <CommonSubHeader>
          Contact us today for a free inspection
        </CommonSubHeader>
        <CommonDescription aria-label="Reach out to our team to receive your free inspection">
          Concerned about the condition of your metal roof? Noble Assurance
          Group offers free, no-obligation inspections to assess the state of
          your roof and recommend the best course of action. Our experienced
          professionals will thoroughly examine your roof for any signs of
          damage or wear, providing you with a detailed report and expert
          advice. Don't wait until minor issues become major problems—contact us
          today to schedule your free metal roof inspection and ensure your home
          remains protected and beautiful.
        </CommonDescription>
      </SpringBox>
    </CommonRouteContainer>
  );
}
