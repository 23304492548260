import { Box } from "@mui/material";
import CommonDescription from "../../shared/components/Common/CommonDescription";
import CommonHeader from "../../shared/components/Common/CommonHeader";
import CommonRouteImageWithTextHeader from "../../shared/components/Common/CommonRouteImageWithTextHeader";
import SpringBox from "../../shared/components/SpringBox";
import CommonRouteContainer from "../../shared/components/container/CommonRouteContainer";
import ContactForm from "../contact/ContactForm";
import MailingList from "../mailing-list/MailingList";
import NewOrleansVideo from "./NewOrleansVideo";

export default function Home() {
  return (
    <CommonRouteContainer
      titlePrefix="Home"
      metaDescription="At Noble Assurance Group, our goal is to provide potential buyers,
          sellers, and homeowners with a comprehensive understanding of their
          property's structural integrity, safety, and functionality"
      aria-label="Home page"
    >
      <CommonRouteImageWithTextHeader
        alt="Heritage Park Video"
        video={require("./HeritageParkBounce.mp4")}
        header="Inspected once, inspected right"
        subHeader="Communication, transparency, execution"
        aria-label="header with Heritage Park video"
      />
      <Box aria-label="Main content box">
        <CommonHeader aria-label="Noble assurance group header">
          Noble Assurance Group
        </CommonHeader>
        <CommonDescription aria-label="Description of noble assurance group">
          At Noble Assurance Group, our goal is to provide potential buyers,
          sellers, and homeowners with a comprehensive understanding of their
          property's structural integrity, safety, and functionality. We are
          trained professionals who specialize in assessing your home's major
          components, and since our home inspections are completely
          non-invasive, we will never perform any destructive testing or
          dismantle any structures. Our detailed reports allow you to make
          informed decisions about your property, both saving you time and money
          and granting you peace of mind.
        </CommonDescription>
      </Box>
      <NewOrleansVideo />
      <SpringBox>
        <ContactForm aria-label="Contact form" />
      </SpringBox>
      <SpringBox>
        <MailingList aria-label="Mailing list sign up form" />
      </SpringBox>
    </CommonRouteContainer>
  );
}
