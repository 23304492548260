import {
  faHouseMedical,
  faLock,
  faThermometerHalf,
} from "@fortawesome/free-solid-svg-icons";
import CommonDescription from "../../../shared/components/Common/CommonDescription";
import CommonRouteImageWithTextHeader from "../../../shared/components/Common/CommonRouteImageWithTextHeader";
import CommonRouteContainer from "../../../shared/components/container/CommonRouteContainer";
import SpringBox from "../../../shared/components/SpringBox";
import SummaryIconContainer from "../../../shared/components/Summary/SummaryIconContainer";

export default function RoofCoatings() {
  return (
    <CommonRouteContainer
      titlePrefix="Roof coatings"
      metaDescription="Roof coatings offer a versatile and cost-effective solution for
          extending the life of commercial roofs while enhancing their
          performance and energy efficiency"
    >
      <CommonRouteImageWithTextHeader
        image={require("./RoofCoating.jpg")}
        alt={"Roof coating"}
        header={"Roof coatings"}
        subHeader={"Protection for your investment"}
        aria-label="Roof coatings header and image"
      />
      <SummaryIconContainer
        aria-label="Summary icons"
        icons={[
          {
            icon: faThermometerHalf,
            text: "Improves energy efficiency",
          },
          {
            icon: faHouseMedical,
            text: "Extends roof life",
          },
          {
            icon: faLock,
            text: "Protects your investment",
          },
        ]}
      />
      <SpringBox>
        <CommonDescription aria-label="What roof coatings offer">
          Roof coatings offer a versatile and cost-effective solution for
          extending the life of commercial roofs while enhancing their
          performance and energy efficiency. At Noble Assurance Group, we
          specialize in applying high-quality roof coatings that provide
          superior protection and durability.
        </CommonDescription>
        <CommonDescription aria-label="What roof coatings are designed to do">
          Designed to seal and protect existing roofs, roof coatings create a
          seamless barrier against water infiltration, UV radiation, and
          weathering. These coatings can be applied to various roofing
          materials, including metal, asphalt, and single-ply membranes like TPO
          and PVC. By preventing leaks and reducing thermal shock, roof coatings
          help maintain the integrity of the roof structure and significantly
          prolong its lifespan.
        </CommonDescription>
        <CommonDescription aria-label="Additional benefits of roof coatings">
          In addition to waterproofing benefits, roof coatings can improve
          energy efficiency by reflecting solar heat away from the building,
          thereby reducing cooling costs during hot weather. This can lead to
          significant savings on energy bills over time.
        </CommonDescription>
        <CommonDescription aria-label="How our team can help">
          At Noble Assurance Group, our experienced team ensures precise
          application of roof coatings, tailored to meet the specific needs of
          your commercial property. Whether you're looking to extend the life of
          an aging roof or enhance the energy efficiency of your building, trust
          us to deliver reliable solutions that protect your investment.
        </CommonDescription>
        <CommonDescription aria-label="Contact us for a free inspection">
          Contact us today to learn more about our roof coating services for
          commercial properties and schedule a consultation with one of our
          roofing specialists.
        </CommonDescription>
      </SpringBox>
    </CommonRouteContainer>
  );
}
