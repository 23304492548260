import { combineReducers, configureStore } from "@reduxjs/toolkit";
import localforage from "localforage";
import { persistReducer, persistStore } from "redux-persist";
import mailingListReducer from "./mailingListSlice";
import paymentCalculatorReducer from "./paymentCalculatorSlice";
import contactFormReducer from "./contactFormSlice";

const persistConfig = {
  key: "root",
  storage: localforage, // for chunking
};

export const reduxSlices = {
  mailingList: mailingListReducer,
  paymentCalculator: paymentCalculatorReducer,
  contactForm: contactFormReducer,
};

const rootReducer = combineReducers(reduxSlices);
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
