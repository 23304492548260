import {
  groupedSectionPadding,
  smallGroupedSectionPadding,
} from "../Constants/PaddingConstants";
import useScalableSizes from "./useScalableSizes";

export const useGroupedSectionPadding = (): string => {
  const { isLargerThanSe } = useScalableSizes();
  return isLargerThanSe ? groupedSectionPadding : smallGroupedSectionPadding;
};
