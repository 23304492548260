import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface PaymentCalculatorState {
  loanAmount: string;
  interestRate: string;
  loanTerm: string;
  estimatedMontlyPayment: string;
}

const initialState: PaymentCalculatorState = {
  loanAmount: "",
  interestRate: "",
  loanTerm: "",
  estimatedMontlyPayment: "",
};

export const paymentCalculatorSlice = createSlice({
  name: "paymentCalculator",
  initialState,
  reducers: {
    setLoanAmount: (state, action: PayloadAction<string>) => {
      state.loanAmount = action.payload;
    },
    setInterestRate: (state, action: PayloadAction<string>) => {
      state.interestRate = action.payload;
    },
    setLoanTerm: (state, action: PayloadAction<string>) => {
      state.loanTerm = action.payload;
    },
    setEstimatedMonthlyPayment: (state, action: PayloadAction<string>) => {
      state.estimatedMontlyPayment = action.payload;
    },
    resetPaymentCalculatorSlice: () => initialState,
  },
});

export const {
  setLoanAmount,
  setInterestRate,
  setLoanTerm,
  setEstimatedMonthlyPayment,
  resetPaymentCalculatorSlice,
} = paymentCalculatorSlice.actions;
export default paymentCalculatorSlice.reducer;
