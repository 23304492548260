import { Typography } from "@mui/material";
import HoverableLink from "../Link/HoverableLink";

interface CaptchaBadgeProps {
  maxWidth?: string;
}

export default function CaptchaBadge({
  maxWidth = "450px",
}: CaptchaBadgeProps) {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontSize: "14px",
        color: "#626262",
        textAlign: "start",
        maxWidth: maxWidth,
      }}
    >
      This site is protected by reCAPTCHA and the Google{" "}
      <HoverableLink linkTo="https://policies.google.com/privacy">
        Privacy Policy
      </HoverableLink>{" "}
      and{" "}
      <HoverableLink linkTo="https://policies.google.com/terms">
        Terms of Service
      </HoverableLink>{" "}
      apply.
    </Typography>
  );
}
