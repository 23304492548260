import { CssBaseline, ThemeProvider } from "@mui/material";
import { ModalProvider } from "query-string-modals";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import PersistGateLoading from "../redux/PersistGate/PersistGateLoading";
import { persistor, store } from "../redux/store";
import { captchaSiteKey } from "../shared/components/Google/GoogleConstants";
import { AlertProvider } from "../shared/context/AlertContext";
import DocumentTitleProvider from "../shared/context/DocumentTitleContext";
import { FooterHeightProvider } from "../shared/context/FooterHeightContext";
import ScrollPositionProvider from "../shared/context/ScrollPositionContext";
import App from "./App";
import { appModals } from "./AppModals";
import { muiTheme } from "./Theme";

export default function AppContextAndWrappers() {
  return (
    <ThemeProvider theme={muiTheme}>
      <AlertProvider>
        <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey}>
          <style>{`.grecaptcha-badge { visibility: hidden !important; }`}</style>
          <DocumentTitleProvider>
            <ScrollPositionProvider>
              <FooterHeightProvider>
                <Provider store={store}>
                  <PersistGate persistor={persistor}>
                    {(persistenceLoaded) => {
                      if (persistenceLoaded) {
                        return (
                          <ModalProvider modals={appModals}>
                            <CssBaseline />
                            <App />
                          </ModalProvider>
                        );
                      } else {
                        return <PersistGateLoading />;
                      }
                    }}
                  </PersistGate>
                </Provider>
              </FooterHeightProvider>
            </ScrollPositionProvider>
          </DocumentTitleProvider>
        </GoogleReCaptchaProvider>
      </AlertProvider>
    </ThemeProvider>
  );
}
