import { Alert, Box, IconButton } from "@mui/material";
import React, { createContext, useContext, useEffect, useState } from "react";

type severity = "success" | "info" | "warning" | "error";

interface AlertContextState {
  alertMessage: string;
  severity: severity;
  showSuccessAlert: (message: string, customAction?: CustomAction) => void;
  showInfoAlert: (message: string, customAction?: CustomAction) => void;
  showWarningAlert: (message: string, customAction?: CustomAction) => void;
  showErrorAlert: (message: string, customAction?: CustomAction) => void;
  hideAlert: () => void;
}

interface CustomAction {
  icon: React.ReactNode;
  action?: () => void;
}

const AlertContext = createContext<AlertContextState | undefined>(undefined);

interface AlertProviderProps {
  children: React.ReactNode;
}

export function AlertProvider({ children }: AlertProviderProps) {
  // Constants
  const visibleForTime = 8000;

  // State
  const [state, setState] = useState<{
    alertMessage: string;
    severity: severity;
    showAlert: boolean;
    customAction?: CustomAction;
  }>({
    alertMessage: "",
    severity: "error",
    showAlert: false,
  });

  function showAlert(
    alertMessage: string,
    severity: severity,
    customAction?: CustomAction
  ) {
    setState({ alertMessage, severity, showAlert: true, customAction });
  }

  function showSuccessAlert(alertMessage: string, customAction?: CustomAction) {
    showAlert(alertMessage, "success", customAction);
  }

  function showInfoAlert(alertMessage: string, customAction?: CustomAction) {
    showAlert(alertMessage, "info", customAction);
  }

  function showWarningAlert(alertMessage: string, customAction?: CustomAction) {
    showAlert(alertMessage, "warning", customAction);
  }

  function showErrorAlert(alertMessage: string, customAction?: CustomAction) {
    showAlert(alertMessage, "error", customAction);
  }

  function hideAlert() {
    setState((prev) => ({ ...prev, showAlert: false }));
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (state.showAlert) {
      timeout = setTimeout(() => hideAlert(), visibleForTime);
    }
    return () => clearTimeout(timeout);
  }, [state.showAlert]);

  return (
    <AlertContext.Provider
      value={{
        ...state,
        showSuccessAlert,
        showInfoAlert,
        showWarningAlert,
        showErrorAlert,
        hideAlert,
      }}
    >
      {children}
      {state.showAlert && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: 75,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "80%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Alert
              severity={state.severity}
              onClose={hideAlert}
              sx={{
                borderRadius: "100px",
                zIndex: 1000,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
              action={
                <>
                  {state.customAction && (
                    <IconButton
                      aria-label="custom-action"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        state.customAction?.action?.();
                      }}
                    >
                      {state.customAction.icon}
                    </IconButton>
                  )}
                </>
              }
            >
              {state.alertMessage}
            </Alert>
          </Box>
        </Box>
      )}
    </AlertContext.Provider>
  );
}

export function useAlert() {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
}
