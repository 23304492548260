import {
  faHammer,
  faHouse,
  faPaintRoller,
} from "@fortawesome/free-solid-svg-icons";
import CommonDescription from "../../../shared/components/Common/CommonDescription";
import CommonRouteImageWithTextHeader from "../../../shared/components/Common/CommonRouteImageWithTextHeader";
import CommonSubHeader from "../../../shared/components/Common/CommonSubHeader";
import CommonRouteContainer from "../../../shared/components/container/CommonRouteContainer";
import SpringBox from "../../../shared/components/SpringBox";
import SummaryIconContainer from "../../../shared/components/Summary/SummaryIconContainer";

export default function Drywall() {
  return (
    <CommonRouteContainer
      titlePrefix="Drywall"
      metaDescription="At Noble Assurance Group, we offer comprehensive drywall services
        designed to meet the diverse needs of both residential and commercial
        properties"
    >
      <CommonRouteImageWithTextHeader
        image={require("./Drywall.png")}
        alt={"Drywall"}
        header={"Drywall"}
        subHeader={"All aspects handled"}
        aria-label="Drywall header and image"
      />
      <SummaryIconContainer
        aria-label="Summary icons"
        icons={[
          {
            icon: faHouse,
            text: "Versatile drywall solutions",
          },
          {
            icon: faPaintRoller,
            text: "Smooth and knockdown",
          },
          {
            icon: faHammer,
            text: "Accurate and durable",
          },
        ]}
      />
      <CommonDescription aria-label="Drywall services we offer">
        At Noble Assurance Group, we offer comprehensive drywall services
        designed to meet the diverse needs of both residential and commercial
        properties. Whether you're renovating, building new, or repairing
        damage, our expert team is dedicated to delivering top-quality drywall
        solutions that enhance the beauty and functionality of your space.
      </CommonDescription>
      <SpringBox>
        <CommonSubHeader>
          Comprehensive drywall installation and finishing
        </CommonSubHeader>
        <CommonDescription aria-label="What our services provide and our professional attention to detail">
          Our drywall installation services provide a solid foundation for your
          interiors, ensuring a smooth and durable finish. We handle all aspects
          of the installation process, from measuring and cutting to securing
          and finishing. With a variety of textures and finishes available,
          including smooth, knockdown, and orange peel, we can create the
          perfect look for your walls and ceilings.
        </CommonDescription>
      </SpringBox>
    </CommonRouteContainer>
  );
}
