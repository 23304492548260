import { Box } from "@mui/material";
import { useEffect } from "react";
import Navbar from "../components/navbar/Navbar";
import { useScrollPositionContext } from "../shared/context/ScrollPositionContext";
import { reloadFonts } from "./FontLoader";
import WebsiteRoutes from "./WebsiteRoutes";
import { useLocation } from "react-router-dom";

/**
 * The default component for initializing components such as the header and current route contet.
 */
export default function App() {
  // Context
  const { setPrimaryScrollViewRef } = useScrollPositionContext();

  // Hooks
  const location = useLocation();

  // Effects
  useEffect(() => reloadFonts(), [location.pathname]);

  useEffect(() => {
    console.debug(
      "Website built with <3 by Nathan Cheshire; nathancheshire.com"
    );
    console.debug(`Version: ${process.env.REACT_APP_GIT_COMMIT_HASH}`);
  }, []);

  return (
    <Box
      ref={setPrimaryScrollViewRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        background: "white",
        position: "relative",
        scrollBehavior: "auto",
        overflowY: "overlay",
        overflowX: "hidden",
        top: 0,
        right: 0,
      }}
    >
      <Navbar />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <WebsiteRoutes />
      </Box>
    </Box>
  );
}
