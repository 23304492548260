import { Box } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import { nobleOrange } from "../../assets/Colors";
import { useGroupedSectionPadding } from "../../shared/hooks/useGroupedSectionPadding";
import NumberIncrement from "./NumberIncrement";

export default function ExperiencedAndProfessional() {
  // Constants
  const inspections = 185;
  const imagesPerReport = 40;
  const yearsOfExperience = 6;
  const animationDuration = 2000;

  // State
  const [currentInspections, setCurrentInspections] = useState<number>(0);
  const [currentImagesPerReport, setCurrentImagesPerReport] =
    useState<number>(0);
  const [currentYearsOfExperience, setCurrentYearsOfExperience] =
    useState<number>(0);
  const [isInView, setIsInView] = useState<boolean>(false);

  // Ref
  const containerRef = useRef<HTMLDivElement>(null);

  // Effects
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.unobserve(entry.target);
        }
      },
      {
        threshold: 0.4, // Trigger when 10% of the component is visible
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isInView) return;

    const animateNumber = (
      finalValue: number,
      setValue: Dispatch<SetStateAction<number>>
    ) => {
      const startTime = Date.now();
      const updateValue = () => {
        const elapsedTime = Date.now() - startTime;
        const progress = Math.min(elapsedTime / animationDuration, 1);
        const currentValue = Math.floor(progress * finalValue);

        setValue(currentValue);

        if (progress < 1) {
          requestAnimationFrame(updateValue);
        }
      };
      requestAnimationFrame(updateValue);
    };

    animateNumber(inspections, setCurrentInspections);
    animateNumber(imagesPerReport, setCurrentImagesPerReport);
    animateNumber(yearsOfExperience, setCurrentYearsOfExperience);
  }, [isInView, inspections, imagesPerReport, yearsOfExperience]);

  return (
    <Box
      ref={containerRef}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: "20px",
        paddingX: useGroupedSectionPadding(),
      }}
    >
      <NumberIncrement
        number={currentInspections}
        label={"inspections"}
        color={nobleOrange}
      />
      <NumberIncrement
        number={currentImagesPerReport}
        label={"images per report"}
        color={nobleOrange}
      />
      <NumberIncrement
        number={currentYearsOfExperience}
        label={"years of experience"}
        color={nobleOrange}
      />
    </Box>
  );
}
