import { faCloudRain, faHouseLock } from "@fortawesome/free-solid-svg-icons";
import CommonDescription from "../../../shared/components/Common/CommonDescription";
import CommonRouteImageWithTextHeader from "../../../shared/components/Common/CommonRouteImageWithTextHeader";
import CommonRouteContainer from "../../../shared/components/container/CommonRouteContainer";
import SpringBox from "../../../shared/components/SpringBox";
import SummaryIconContainer from "../../../shared/components/Summary/SummaryIconContainer";
import { faSun } from "@fortawesome/free-regular-svg-icons";

export default function ModifiedBitumenRoofing() {
  return (
    <CommonRouteContainer
      titlePrefix="Modified bitumen roofing"
      metaDescription="Modified bitumen roofing is a durable and reliable choice for flat or
          low-slope roofs, offering excellent waterproofing and weather
          resistance"
    >
      <CommonRouteImageWithTextHeader
        image={require("./ModifiedBitumenRoofing.png")}
        alt={"Modified bitumen roofing"}
        header={"Modified bitumen roofing"}
        subHeader={"Durable and flexible"}
        aria-label="Modified bitumen roofing header image"
      />
      <SummaryIconContainer
        aria-label="Summary icons"
        icons={[
          {
            icon: faCloudRain,
            text: "Weather and waterproof",
          },
          {
            icon: faHouseLock,
            text: "Strength and flexibility",
          },
          {
            icon: faSun,
            text: "Energy-efficient roofs",
          },
        ]}
      />
      <CommonDescription aria-label="Durable and reliable">
        Modified bitumen roofing is a durable and reliable choice for flat or
        low-slope roofs, offering excellent waterproofing and weather
        resistance. At Noble Assurance Group, we specialize in installing and
        maintaining high-quality modified bitumen roofing systems designed to
        withstand the challenges of commercial and residential buildings alike.
      </CommonDescription>
      <SpringBox>
        <CommonDescription aria-label="How the roof works and what it is resistant to">
          This type of roofing consists of layers of asphalt-based sheets
          reinforced with fiberglass or polyester to enhance strength and
          durability. Known for its flexibility, modified bitumen roofs are
          ideal for buildings that experience temperature fluctuations and
          movement. Modified bitumen roofs are also resistant to UV radiation
          and can effectively reflect heat, improving energy efficiency and
          reducing cooling costs.
        </CommonDescription>
        <CommonDescription aria-label="How our team installs these roofs">
          Our experienced team ensures precise installation and meticulous
          attention to detail, ensuring your modified bitumen roof provides
          long-lasting protection and peace of mind. Whether you're looking to
          install a new roof or need maintenance and repairs for an existing
          system, Noble Assurance Group is your trusted partner for reliable
          modified bitumen roofing solutions. Contact us today to learn more
          about our modified bitumen roofing services and schedule a
          consultation with one of our roofing experts.
        </CommonDescription>
      </SpringBox>
    </CommonRouteContainer>
  );
}
