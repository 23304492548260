import { Box, Button, Typography, lighten } from "@mui/material";
import { useRef } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { footerBackground, nobleOrange, offWhite } from "../../assets/Colors";
import { useAppDispatch, useAppSelector } from "../../redux/ReduxHooks";
import { setEmailAddress } from "../../redux/mailingListSlice";
import { smallGroupedSectionGap } from "../../shared/Constants/PaddingConstants";
import { emailAddressRegex } from "../../shared/Constants/Regex";
import { subscribeToMailingList } from "../../shared/Mailchimp/MailchimpService";
import CommonSubHeader from "../../shared/components/Common/CommonSubHeader";
import CaptchaBadge from "../../shared/components/Google/CaptchaBadge";
import { CommonReduxField } from "../../shared/components/container/CommonReduxField";
import { useAlert } from "../../shared/context/AlertContext";
import useScalableSizes from "../../shared/hooks/useScalableSizes";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link } from "react-router-dom";
import { mailingListForm } from "../../shared/Constants/FormConstants";

/**
 * The form allowing for a user to subscribe to the mailing list.
 */
export default function MailingList() {
  // Refs
  const fieldRef = useRef<any>(null);

  // Redux
  const mailingListEmail = useAppSelector(
    (state) => state.mailingList.emailAddress
  );

  const dispatch = useAppDispatch();

  // Context
  const { showInfoAlert, showErrorAlert } = useAlert();
  const { descriptionTextSize } = useScalableSizes();

  // Hooks
  const { executeRecaptcha } = useGoogleReCaptcha();

  function validateEmailAddress(value: string) {
    if (!value.trim()) return "Email required";
    else if (!emailAddressRegex.test(value.trim()))
      return "Invalid email address";
    else return "";
  }

  async function onSubscribeRequested() {
    if (!executeRecaptcha) return;
    const gcaptcha = await executeRecaptcha();

    if (fieldRef.current) {
      fieldRef.current.touch();
      fieldRef.current.triggerHelperTextEvaluation();
    }

    if (validateEmailAddress(mailingListEmail)) return;

    subscribeToMailingList(
      mailingListEmail.trim(),
      gcaptcha,
      onMailingListSubscribeSuccess,
      onMailingListSubscribeFailure
    );
  }

  function onMailingListSubscribeSuccess() {
    showInfoAlert(`Subscribed to the mailing list`);
    dispatch(setEmailAddress(""));
    fieldRef.current.untouch();
  }

  function onMailingListSubscribeFailure() {
    showErrorAlert("Failed to subscribe; please fill out the manual form", {
      icon: (
        <Link target="_blank" to={mailingListForm}>
          <LaunchIcon />
        </Link>
      ),
    });
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: smallGroupedSectionGap,
      }}
    >
      <CommonSubHeader>Join the mailing list</CommonSubHeader>
      <Typography
        sx={{
          fontFamily: "Roboto",
          maxWidth: "350px",
          textAlign: "justify",
          fontSize: descriptionTextSize,
        }}
      >
        Sign up for our mailing list and receive exlusive content on offers,
        tips, and free information! It's free!
      </Typography>
      <Box height={smallGroupedSectionGap} />
      <CommonReduxField
        ref={fieldRef}
        label={"Your email"}
        value={mailingListEmail}
        setter={setEmailAddress}
        getHelperText={validateEmailAddress}
        onEnter={onSubscribeRequested}
      />
      <Button
        variant="contained"
        fullWidth
        onClick={onSubscribeRequested}
        sx={{
          textTransform: "none",
          fontFamily: "Raleway",
          fontSize: "16px",
          backgroundColor: footerBackground,
          color: offWhite,
          fontWeight: "bold",
          transition: "all 0.15s linear",
          "&:hover": {
            backgroundColor: lighten(nobleOrange, 0.2),
            color: footerBackground,
          },
        }}
      >
        Join
      </Button>
      <CaptchaBadge />
    </Box>
  );
}
