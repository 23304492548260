import axios, { AxiosResponse, HttpStatusCode } from "axios";

const workerUrl =
  "https://noble-assurance-group-mailchimp-workers.nathan-vincent-2-718.workers.dev";

const postHeaders = {
  "Content-Type": "application/json",
};

/**
 * Sends the contact form submission to Mailchimp using the Cloudflare worker.
 * On success or failure executes the proper callback.
 */
export function sendContactForm(
  fullName: string,
  preferredName: string,
  emailAddress: string,
  phoneNumber: string,
  message: string,
  captcha: string,
  onSuccess?: () => void,
  onFailure?: () => void
) {
  const contactBody = {
    fullName: fullName,
    preferredName: preferredName,
    email: emailAddress,
    phoneNumber: phoneNumber,
    message: message,
    captcha: captcha,
  };

  axios
    .post(`${workerUrl}/contact`, contactBody, { headers: postHeaders })
    .then((response: AxiosResponse) => {
      if (response.status === HttpStatusCode.Ok) {
        onSuccess?.();
      } else {
        onFailure?.();
      }
    })
    .catch((error: any) => {
      console.error(error);
      onFailure?.();
    });
}

/**
 * Attempts to subscribe the provided email address to the mailing list using the
 * Cloudflare worker. On success or failure executes the proper callback.
 */
export function subscribeToMailingList(
  email: string,
  captcha: string,
  onSuccess?: () => void,
  onFailure?: () => void
): void {
  const subscribeBody = {
    email: email.trim(),
    captcha: captcha,
  };

  axios
    .post(`${workerUrl}/subscribe`, subscribeBody, {
      headers: postHeaders,
    })
    .then((response: AxiosResponse) => {
      if (response.status === HttpStatusCode.Ok) {
        onSuccess?.();
      } else {
        onFailure?.();
      }
    })
    .catch((error: any) => {
      console.error(error);
      onFailure?.();
    });
}
